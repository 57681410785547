import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import classNames from 'classnames'
import isInBrowser from '@helpers/is-in-browser'
import { allSiteBuilds } from '@variables/global-variables'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  geoButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  geoMenuContainer: {
    cursor: 'pointer',
    position: 'relative',
    padding: '0.5rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
  },
  geoMenu: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: '38px',
    zIndex: 1000,
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      position: 'static',
    },
  },
  globeIcon: {
    height: '23px',
    width: '23px',
  },
  hideGeoMenu: {
    display: 'none',
  },
  geoItem: {
    padding: '0.4rem 1rem',
    fontSize: '0.8rem',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.tertiary,
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
    },
    '&:first-child': {
      paddingTop: '0.6rem',
    },
    '&:last-child': {
      paddingBottom: '0.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
}))

const GeoDropdown = ({
  alternates,
  geoOpen,
  handleGeoMenuClick,
  handleGeoOpen,
  handleGeoClose,
  isMobile,
  ...props
}) => {
  const classes = useStyles(props)
  const locale = process.env.GATSBY_LOCALE || 'us'
  const [pathname, setPathname] = useState('/')
  const [currentDomain, setCurrentDomain] = useState(
    'https://construction.autodesk.com'
  )

  useEffect(() => {
    if (isInBrowser) {
      setCurrentDomain(window.location.origin)
      setPathname(window.location.pathname)
    }
  }, [setCurrentDomain, setPathname])

  // Build reference object from storyblok alternates
  const storyblokAlternates =
    !!alternates &&
    Object.fromEntries([
      ...alternates.map((alternate) => [
        alternate.full_slug.slice(0, 2),
        alternate.full_slug.replace('/homepage', '/').slice(2),
      ]),
      [locale, pathname],
    ])

  // If no corresponding page for a given build(referenced from storyblokAlternates), link to "/" for that TLD
  const Links = () => {
    return allSiteBuilds
      .filter((alternate) => alternate.url !== currentDomain)
      .sort(({ text: a }, { text: b }) => (a > b ? 1 : -1))
      .map((alternate) => {
        const link = `${alternate.url}${
          (storyblokAlternates &&
            storyblokAlternates[alternate.primaryBuildFolder]) ||
          ''
        }`
        return (
          <Box key={alternate.text} className={classes.geoItem}>
            <a href={link}>{alternate.text}</a>
          </Box>
        )
      })
  }

  return (
    <Box
      id="geoDropdown"
      className={classes.geoMenuContainer}
      onClick={handleGeoMenuClick}
      onMouseEnter={handleGeoOpen}
      onMouseLeave={handleGeoClose}
    >
      <Box className={classes.geoButton}>
        <Icon alt="globe icon" styles={classes.globeIcon}>
          GeoIcon
        </Icon>
      </Box>
      <Box
        className={classNames(classes.geoMenu, {
          [classes.hideGeoMenu]: !geoOpen,
        })}
      >
        <Links />
      </Box>
    </Box>
  )
}

export default GeoDropdown
