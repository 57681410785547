import PageContext from '@context'
import getPhoneRegion from '@helpers/get-phone-region'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '@themes'
import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  phone: ({ mobile, isDark, dashed }) => ({
    fontSize: mobile ? 18 : 14,
    fontWeight: theme.typography.fontWeightMedium,
    color: ACC_THEME.palette.text[!isDark ? 'tertiary' : 'white'],
    textDecoration: 'none',
    borderBottom: mobile && dashed ? 'dashed 1px #FFFFFF' : 'none',
  }),
}))

const TopNavPhoneNumber = ({
  number,
  defaultNumber,
  mobile,
  isDark,
  showContactUs,
  dashed,
}) => {
  const phoneQuery = useStaticQuery(graphql`
    query {
      allStoryblokDatasourceEntry(
        filter: { data_source: { eq: "phone-numbers" } }
      ) {
        nodes {
          name
          value
        }
      }
    }
  `)
  const phoneData = phoneQuery.allStoryblokDatasourceEntry?.nodes?.reduce(
    (obj, node) => {
      return { ...obj, [node.name]: node.value }
    },
    {}
  )
  const context = useContext(PageContext)
  const locale = context.locale
  const classes = useStyles({ mobile, isDark, dashed })
  const localePhoneNumber =
    phoneData[locale] || phoneData[getPhoneRegion(locale)]

  const displayNumber = number || localePhoneNumber || defaultNumber

  return (
    <a
      className={classes.phone}
      href={`tel:+${displayNumber.replace(/\D/g, '')}`}
    >
      {showContactUs ? `Contact Us: ${displayNumber}` : displayNumber}
    </a>
  )
}

export default TopNavPhoneNumber
