import React from 'react'
import renderBlok from '@renderBlok'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import ACC_THEME from '@themes'
import BottomLinks from './BottomLinks'

const Menu = (props) => {
  const mdDown = useMediaQuery(ACC_THEME.breakpoints.down('md'))
  const mdOnly = useMediaQuery(ACC_THEME.breakpoints.only('md'))
  const mdUp = useMediaQuery(ACC_THEME.breakpoints.up('md'))

  const MenuItem = ({ menu }) => {
    const hasMenu2 = !!menu.menu2 && !!menu.menu2.length
    const hasMenu1 = !!menu.menu1 && !!menu.menu1.length

    return mdUp ? (
      <Grid item md={hasMenu2 ? 4 : 2}>
        <Box>{renderBlok(menu)}</Box>
      </Grid>
    ) : hasMenu1 || hasMenu2 ? (
      <Grid item md={hasMenu2 ? 4 : 2}>
        <Box>{renderBlok(menu)}</Box>
      </Grid>
    ) : (
      <></>
    )
  }

  return (
    <Grid
      container
      direction={!mdDown || mdOnly ? 'row' : 'column'}
      justifyContent="space-between"
      spacing={2}
    >
      {!!props.menuItems.menus &&
        props.menuItems.menus.map((menu) => (
          <MenuItem key={menu._uid} menu={menu} />
        ))}
      <BottomLinks bottomLinkItems={props.menuItems} />
    </Grid>
  )
}

export default Menu
