import React from 'react'
import { Typography } from '@material-ui/core'

const H3 = ({ children, component, ...otherProps }) => {
  return (
    <Typography
      component={component || 'h3'}
      variant="h3"
      color="primary"
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default H3
