import queryString from 'query-string'
import isInBrowser from '@helpers/is-in-browser'
import removeBothEndSlashes from '@helpers/remove-both-end-slashes'

const urlMatches = (path, matchUrl) => {
  return removeBothEndSlashes(matchUrl).indexOf(removeBothEndSlashes(path)) > -1
}

const isCurrentUrl = matchUrl => {
  if (isInBrowser) {
    let pathname = removeBothEndSlashes(window.location.pathname)
    const query = window.location.search

    if (pathname === '' || pathname === '/') return false

    // Check if viewing in the Storyblok editor
    if (pathname.indexOf('editor') === 0 && query.length > 1) {
      const params = queryString.parse(query)['path']
      pathname =
        !!params && params.indexOf('/') > -1
          ? params.substring(0, params.indexOf('/'))
          : params
    }

    return urlMatches(pathname, matchUrl)
  }
  return false
}

export default isCurrentUrl
