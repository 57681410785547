import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  Hidden,
  Button,
  Divider,
} from '@material-ui/core'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ACC_THEME from '@themes'
import isCurrentUrl from '@helpers/is-current-url'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { getCookie } from '@cookies'
import { getGeoBannerUrl } from '@helpers/localization'
import { Img } from '@system'
import PhoneNumber from './phone-number'
import ContentContainer from '@system/content-container'
import PageContext from '@context'
import MenuItem from '@material-ui/core/MenuItem'
import MuiLink from '@material-ui/core/Link'
import MenuList from '@material-ui/core/MenuList'
import GeoDropdown from './geo-dropdown'
import Popover from '@material-ui/core/Popover'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Icon from '@system/icon'
import { LogoLink } from './shared'

import MobileMenu from './mobile-menu'
import classNames from 'classnames'

const a11yProps = (index) => {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

const useStyles = makeStyles({
  root: {
    textTransform: 'none',
    padding: '0',
    minWidth: 'initial',
    fontSize: 16,
    color: ACC_THEME.palette.common.white,
    opacity: '1',
    '& .MuiTab-wrapper': {
      margin: '0 5px 0  0',
      height: '100%',
      zIndex: '100',
    },
  },
  tab: {
    height: 60,
  },
  topNavContainer: {
    diplay: 'flex',
  },
  navArrow: {
    color: ACC_THEME.palette.common.white,
    height: '60px',
    zIndex: '100',
  },
})

const useTabsStyles = makeStyles({
  root: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    opacity: '1',
    overflow: 'visible',
    '& > div': {
      overflow: 'visible !important',
    },
  },
  indicator: {
    backgroundColor: '#0696D7',
    height: 6,
    display: 'none',
  },
  phone: {
    fontSize: 14,
    fontWeight: 500,
    color: ACC_THEME.palette.common.white,
    textDecoration: 'none',
  },
  phone2: {
    fontSize: 14,
    fontWeight: 500,
    color: '#FFFFFF',
    textDecoration: 'none',
    borderBottom: 'dashed 1px #FFFFFF',
  },
})

const useStylesDrawer = makeStyles(() => ({
  linkTabContainer: {
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: ACC_THEME.palette.common.white,
  },
  activeLinkTab: {
    backgroundColor: ACC_THEME.palette.background.dark,
  },
  topNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    display: 'flex',
  },
  subMenuPane: {
    backgroundColor: ACC_THEME.palette.common.white,
    borderRadius: '0 0 10px 10px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    height: 'auto',
    left: '0px',
    overflow: 'hidden',
    position: 'absolute',
    top: '60px',
    whiteSpace: 'normal',
    width: '100%',
    zIndex: 1,
    [ACC_THEME.breakpoints.up('lg')]: {
      left: ({ dropdownLayout }) =>
        dropdownLayout === 'variable' ? 'unset' : '0px',
      width: ({ dropdownLayout }) =>
        dropdownLayout === 'variable' ? 'max-content' : '100%',
    },
  },
  appBarShift: {
    width: '100%',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    borderRight: '0px',
    backgroundColor: '#333333',
    color: ACC_THEME.palette.common.white,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: '24px',
  },
  topNavBar: {
    position: 'sticky',
    top: '0',
    zIndex: 1300,
  },
  appBar: {
    '&:hover': {
      zIndex: 1200,
    },
  },
  sidebarContainer: {
    padding: '0px',
    minHeight: '572px',
    height: '100%',
    display: 'flex',
  },
  menuLoginCustom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0.5rem 1rem 1rem',
    backgroundColor: '#FFF',
    [ACC_THEME.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
    },
    '& a': {
      [ACC_THEME.breakpoints.down('sm')]: {
        color: '#FFFFFF',
      },
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  loginItemLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: '0.5rem 0 0 !important',
    fontSize: 14,
    fontWeight: 700,
    width: '100%',
  },
  loginItemSubLink: {
    width: '100%',
    height: 'auto',
    paddingLeft: 34,
    display: 'flex',
    flexDirection: 'column',
  },
  iconLoginCustom: {
    color: ACC_THEME.palette.common.white,
  },
  menuLoginDivider: {
    backgroundColor: '#333333',
    height: '32px',
    margin: '0 12px 0 20px',
    width: '1px',
  },
  menuLoginCustomButton: {
    color: ACC_THEME.palette.common.white,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    backgroundColor: ACC_THEME.palette.common.white,
    pointerEvents: 'auto',
    boxShadow:
      '0px 5px 60px rgba(255, 255, 255, 0.25), 0px 4px 20px 10px rgba(0, 0, 0, 0.1)',
  },
  drawerHeadline: {
    padding: '0',
    marginBottom: '20px',
    backgroundColor: 'transparent',
    '& span': {
      color: ACC_THEME.palette.common.white,
      fontSize: 18,
      lineHeight: '25px',
      fontWeight: ACC_THEME.typography.fontWeightMedium,
    },
    '& svg': {
      fill: ACC_THEME.palette.common.white,
      height: '28px',
      width: '28px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  geoDrawer: {},
  geoIcon: {
    minWidth: '36px',
  },
  geoIconInner: {
    lineHeight: '24px !important',
  },
  loginItem: {
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mobileSublink: {
    textTransform: 'none',
    marginRight: '4px',
    display: 'flex',
    flexDirection: 'column',
    [ACC_THEME.breakpoints.down('xs')]: {
      marginRight: '14px',
    },
    '& a': {
      [ACC_THEME.breakpoints.down('md')]: {
        color: ACC_THEME.palette.common.white,
      },
    },
  },
  fullWidthMobileNavItem: {
    width: '100%',
  },
  phone: {
    marginLeft: '24px',
  },
}))

const LinkTab = ({ isLink, ...props }) => {
  const classes = useStyles()
  return (
    <>
      <Tab
        component={isLink ? 'a' : 'span'}
        className={classes.tab}
        classes={{ root: classes.root }}
        onClick={() => {}}
        {...props}
      >
        {props.children}
      </Tab>
      {props.selected ? (
        <Icon styles={classes.navArrow}>ExpandLess</Icon>
      ) : (
        <Icon styles={classes.navArrow}>ExpandMore</Icon>
      )}
    </>
  )
}

const LoginMenu = ({
  login,
  anchorEl,
  classes,
  handlePopoverOpen,
  handlePopoverClose,
  open,
  loginButtonRef,
  handlePopoverSubOpen,
}) => {
  const loginMenu = get(login, '[0]')
  return (
    <Hidden smDown>
      <div className={classes.menuLoginDivider} />
      <Button
        ref={loginButtonRef}
        className={classes.menuLoginCustomButton}
        style={{ backgroundColor: 'transparent' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {renderBloks(loginMenu.mainButton, {
          isNavCTA: true,
        })}

        {open ? (
          <Icon style={classes.iconLoginCustom}>ExpandLess</Icon>
        ) : (
          <Icon style={classes.iconLoginCustom}>ExpandMore</Icon>
        )}
      </Button>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: handlePopoverSubOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <MenuList className={classes.menuLoginCustom}>
          {loginMenu.menuItems &&
            loginMenu.menuItems.map((navItem, index) => {
              return (
                <div key={navItem._uid}>
                  <MenuItem className={classes.menuItem}>
                    <MuiLink
                      href={navItem.link.url}
                      className={classes.loginItemLink}
                      underline="hover"
                      width="100%"
                      color="textSecondary"
                    >
                      {navItem.icon && (
                        <Img
                          src={get(navItem.icon, '[0]').image}
                          alt={get(navItem.icon, '[0]').title}
                          style={{
                            height: '20px',
                            marginRight: '14px',
                            objectFit: 'contain',
                            objectPosition: 'left',
                            width: '20px',
                          }}
                          width="20px"
                        />
                      )}

                      {navItem.textToHyperlink}
                    </MuiLink>
                    {navItem.subLink && (
                      <Box className={classes.loginItemSubLink}>
                        {renderBloks(navItem.subLink, {
                          isNavCTA: true,
                        })}
                      </Box>
                    )}
                  </MenuItem>
                  {index !== loginMenu.menuItems.length - 1 && (
                    <Divider
                      style={{
                        width: '100%',
                        margin: '0.5rem 0',
                      }}
                    />
                  )}
                </div>
              )
            })}
        </MenuList>
      </Popover>
    </Hidden>
  )
}

const TopNav = (props) => {
  const {
    banner,
    button,
    dropdownLayout,
    login,
    logoImage,
    navItems,
    phoneNumber,
  } = props.blok

  const pageContext = useContext(PageContext)
  const { isInEditor, locale } = pageContext
  const [suggestedUrl, setSuggestedUrl] = useState(false)
  const [isGeoBannerVisible, setIsGeoBannerVisible] = useState(false)

  const geoBannerRef = useRef(null)

  useEffect(() => {
    const geoBannerCookie = getCookie('accbd')
    const geoBannerUrl = getGeoBannerUrl(locale)
    setSuggestedUrl(!geoBannerCookie && geoBannerUrl)

    const shouldShowGeoBanner =
      banner?.length &&
      !props.removeTopBanner &&
      (get(banner, '[0]').component !== 'geoBanner' ||
        (get(banner, '[0]').component === 'geoBanner' &&
          !geoBannerCookie &&
          geoBannerUrl))

    setIsGeoBannerVisible(shouldShowGeoBanner)
  }, [locale, banner, props.removeTopBanner])

  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  const tabsClasses = useTabsStyles()
  const classes = useStylesDrawer({ dropdownLayout })
  const [mobileDrawerStatus, updateMobileDrawerStatus] = useState(false)
  const underlinedTab = navItems.reduce(
    (acc, item, i) => (!!item.path && isCurrentUrl(item.path.url) ? i : acc),
    false
  )

  const [value, setValue] = useState(null)
  const [subMenu, setSubMenu] = useState(false)

  const handleChange = debounce(
    (_e, newValue, menuState) => {
      setValue(newValue)
      setSubMenu(menuState)
    },
    subMenu ? 0 : 175
  )

  const toggleDrawer = (e) => updateMobileDrawerStatus(!mobileDrawerStatus)

  useEffect(() => {
    if (handHeldDevice) setSubMenu(false)
  }, [handHeldDevice])

  const [geoOpen, setGeoOpen] = useState(false)

  const isCustomNavLogin =
    !!props.customNavLogin && props.customNavLogin.length !== 0

  const isDefaultLogin = !!get(login, '[0]')

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const loginButtonRef = React.useRef()
  const handlePopoverSubOpen = () => {
    setAnchorEl(loginButtonRef.current)
  }
  const [openmobilemenu, setOpen] = useState(false)
  const anchorRefMobileMenu = useRef(null)

  const handleClick = () => {
    setOpen(!openmobilemenu)
  }

  const handleGeoOpen = () => {
    setGeoOpen(true)
  }
  const handleGeoClose = () => {
    setGeoOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openmobilemenu)
  useEffect(() => {
    if (prevOpen.current === true && openmobilemenu === false) {
      anchorRefMobileMenu.current.focus()
    }
    prevOpen.current = openmobilemenu
  }, [openmobilemenu])

  return (
    <ThemeProvider theme={ACC_THEME}>
      <CssBaseline />
      <Box id="topNavBar" className={classes.topNavBar}>
        <Box className={classes.appBar} id="appBar">
          {isGeoBannerVisible && (
            <Box ref={geoBannerRef}>
              {renderBloks(banner, {
                isTopNavBanner: true,
                suggestedUrl: suggestedUrl,
              })}
            </Box>
          )}
          {props.customTopBanner && (
            <Box>{renderBloks(props.customTopBanner)}</Box>
          )}
          <Box
            id="top-nav"
            height={60}
            minHeight={60}
            my={0}
            p={0}
            display="flex"
            bgcolor={'primary.main'}
            color="common.white"
            alignItems="center"
          >
            <ContentContainer
              className={classes.topNavContainer}
              backgroundColor={
                props.invertedNav ? 'primary.main' : 'primary.main'
              }
              id="top-nav-row1"
            >
              <LogoLink
                handHeldDevice={handHeldDevice}
                invertedNav={props.invertedNav}
                logoImage={logoImage}
              />
              <div
                style={{ alignItems: 'center', display: 'flex', gap: '16px' }}
              >
                <Box
                  id="top-nav-phone-cta-menu-container"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {!props.isLandingPage && (
                    <Hidden smDown>
                      <GeoDropdown
                        inverted={props.invertedNav}
                        alternates={props.alternates}
                        geoOpen={geoOpen}
                        handleGeoClose={handleGeoClose}
                        handleGeoOpen={handleGeoOpen}
                      />
                    </Hidden>
                  )}
                  <Hidden mdDown>
                    <Box className={classes.phone}>
                      <PhoneNumber
                        number={props.topNavPhone}
                        defaultNumber={phoneNumber}
                        isDark={!props.invertedNav}
                      />
                    </Box>
                  </Hidden>
                  {isCustomNavLogin ? (
                    <LoginMenu
                      login={props.customNavLogin}
                      handlePopoverClose={handlePopoverClose}
                      handlePopoverOpen={handlePopoverOpen}
                      classes={classes}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      loginButtonRef={loginButtonRef}
                      handlePopoverSubOpen={handlePopoverSubOpen}
                    />
                  ) : props.invertedNav ? (
                    <></>
                  ) : isDefaultLogin ? (
                    <LoginMenu
                      login={login}
                      handlePopoverClose={handlePopoverClose}
                      handlePopoverOpen={handlePopoverOpen}
                      classes={classes}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      loginButtonRef={loginButtonRef}
                      handlePopoverSubOpen={handlePopoverSubOpen}
                    />
                  ) : (
                    <></>
                  )}
                  <Hidden xsDown>
                    <Box id="top-nav-cta" ml={2}>
                      {renderBloks(
                        props.topNavCTA && props.topNavCTA.length > 0
                          ? props.topNavCTA
                          : button,
                        { isNavCTA: true }
                      )}
                    </Box>
                  </Hidden>
                </Box>
                {handHeldDevice && !props.removeSubNav && (
                  <div
                    onClick={toggleDrawer}
                    style={{
                      height: '30px',
                      padding: '5px',
                      width: '30px',
                    }}
                  >
                    <img
                      src={
                        'https://a.storyblok.com/f/64835/x/7b7a31bd2d/nav.svg'
                      }
                      alt="Nav Menu Button"
                      style={{
                        display: 'block',
                        width: '100%',
                      }}
                    />
                  </div>
                )}
              </div>
            </ContentContainer>
          </Box>
          {/* secondary nav*/}
          {!handHeldDevice && !props.removeSubNav && (
            <Box
              bgcolor={'common.black'}
              style={{ borderTop: '1px solid #333333' }}
            >
              <ContentContainer
                height={'60px'}
                backgroundColor={'common.black'}
              >
                <Tabs
                  maxWidth={1248}
                  value={value}
                  aria-label="nav tabs top"
                  textColor="inherit"
                  classes={{
                    root: tabsClasses.root,
                    indicator: tabsClasses.indicator,
                  }}
                >
                  {navItems &&
                    navItems.map((navItem, index) => {
                      return (
                        <Box key={navItem._uid}>
                          <Box
                            onMouseEnter={(e) => handleChange(e, index, true)}
                            onMouseLeave={(e) => handleChange(e, false, false)}
                            className={classNames(classes.linkTabContainer, {
                              [classes.activeLinkTab]: index === value,
                            })}
                          >
                            <LinkTab
                              label={navItem.headline}
                              isLink={navItem.isLink}
                              href={navItem.path ? navItem.path.url : '#'}
                              {...a11yProps(0)}
                              selected={index === value && subMenu}
                            />
                          </Box>
                          {subMenu &&
                            navItems[value] &&
                            !navItems[value].isLink &&
                            index === value && (
                              <Box
                                className={classes.subMenuPane}
                                m={0}
                                p={0}
                                onMouseEnter={(e) => {
                                  handleChange(e, value, true)
                                }}
                                onMouseLeave={(e) => {
                                  handleChange(e, underlinedTab, false)
                                }}
                              >
                                {value !== false && renderBlok(navItems[value])}
                              </Box>
                            )}
                        </Box>
                      )
                    })}
                </Tabs>
              </ContentContainer>
            </Box>
          )}
          {!!props.subNav && <Box id="subNav">{renderBlok(props.subNav)}</Box>}
        </Box>
        {/* mobile */}
        {handHeldDevice && mobileDrawerStatus && (
          <MobileMenu
            anchorRefMobileMenu={anchorRefMobileMenu}
            classes={classes}
            geoOpen={geoOpen}
            handleClick={handleClick}
            isCustomNavLogin={isCustomNavLogin}
            isDefaultLogin={isDefaultLogin}
            isInEditor={isInEditor}
            login={login}
            loginButtonRef={loginButtonRef}
            logoImage={logoImage}
            mobileDrawerStatus={mobileDrawerStatus}
            navItems={navItems}
            onKeyDown={handleListKeyDown}
            openmobilemenu={openmobilemenu}
            phoneNumber={phoneNumber}
            setGeoOpen={setGeoOpen}
            toggleDrawer={toggleDrawer}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default TopNav
