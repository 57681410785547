import React from 'react'
import convertUrlToHttps from '@helpers/convert-url-to-https'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Video } from '@system'

const useStyles = makeStyles(() => {
  return {
    videoScreenshotBox: {
      margin: '10px 0px',
      height: '330px',
      objectFit: 'cover',
    },
  }
})

const ScreenshotsPreview = (props) => {
  const classes = useStyles(props)
  const {
    image: { url_video, url, title },
  } = props

  return (
    <Box className={classes.videoScreenshotBox}>
      {url_video ? (
        <Video url={url_video} />
      ) : (
        <img
          src={convertUrlToHttps(url)}
          width="100%"
          height="100%"
          border="1"
          style={{ objectFit: 'contain' }}
          alt={title && title.substr(0, title.lastIndexOf('.'))}
        />
      )}
    </Box>
  )
}

export default ScreenshotsPreview
