import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const PageContainer = (props) => {
  // This module wraps and constrains content on page to the largest breakpoint value
  // Pass prop 'parentWidth' to return content un-constrained
  const { margin, height, backgroundColor, children, parentWidth, styles } =
    props

  const hex = /^#/.test(backgroundColor)
  const linear = /^linear-gradient/.test(backgroundColor)

  const useStyles = makeStyles((theme) => ({
    pageContainerRoot: {
      maxWidth: theme.breakpoints.values.xl,
      margin: margin || '0 auto',
      height: height || 'auto',
      // backgroundColor can be either a hex value or a palette value
      background:
        (hex && backgroundColor) ||
        (linear && backgroundColor) ||
        theme.palette.background[backgroundColor || 'default'],
      ...styles,
    },
  }))

  const classes = useStyles()

  return parentWidth ? (
    children
  ) : (
    <Box
      {...props}
      className={classNames([classes.pageContainerRoot, props.className])}
    >
      {children}
    </Box>
  )
}

export default PageContainer
