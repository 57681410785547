const removePrecedingSlash = str => {
  String.prototype.splice = function(index, count, add) {
    if (index < 0) {
      index = this.length + index
      if (index < 0) {
        index = 0
      }
    }
    return this.slice(0, index) + (add || '') + this.slice(index + count)
  }

  if (!str) {
    return ''
  }
  const trimmed = str.trim()
  return trimmed[0] !== '/' ? trimmed : trimmed.splice(0, 1)
}

export default removePrecedingSlash
