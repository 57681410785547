import React from 'react'
import { Typography } from '@material-ui/core'

const H1 = ({ children, ...otherProps }) => {
  return (
    <Typography
      component="h1"
      variant="h1"
      color="secondary"
      gutterBottom={true}
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default H1
