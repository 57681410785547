import isInBrowser from '@helpers/is-in-browser'

export const isRetinaBrowser = isInBrowser ? window.devicePixelRatio > 1 : false

export const fileIsRetina = (image) =>
  image.includes('-2x') || image.includes('-3x')

export const fileRetinaAmount = (image, _fileIsRetina) =>
  _fileIsRetina && image?.includes('-2x') ? 2 : 3

export const calculateDimension = ({
  value,
  _fileIsRetina,
  _isRetinaBrowser,
  image,
  defaultValue,
  doNotScale,
}) => {
  const dimension = value || defaultValue
  if (!dimension) return undefined
  if (_fileIsRetina) {
    if (doNotScale || _isRetinaBrowser) {
      return dimension
    } else {
      return dimension / fileRetinaAmount(image, _fileIsRetina)
    }
  } else return dimension
}
