import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Box, Tooltip as MUITooltip } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  tooltipRoot: {},
}))

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: '12px',
    padding: '0.75rem',
  },
  arrow: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(MUITooltip)

const Tooltip = ({ blok, styles }) => {
  const classes = useStyles()
  const { icon, tooltipContent } = blok

  return (
    <SbEditable content={blok}>
      <Box className={classNames(classes.tooltipRoot, styles)}>
        <StyledTooltip
          arrow
          interactive
          position="bottom"
          title={<>{renderBloks(tooltipContent)}</>}
        >
          <Box>{renderBloks(icon)}</Box>
        </StyledTooltip>
      </Box>
    </SbEditable>
  )
}

export default Tooltip
