const trackFormSubmission = (formId, dataProduct) => {
  return new Promise((resolve) => {
    // WWFO tracking code (Adobe Analytics)
    window.__analyticsChangeContext = window.__analyticsChangeContext || []
    window.__analyticsChangeContext.push({
      eventData: {
        eventName: 'form_submit,form_success',
      },
      form: {
        platformSystem: 'marketo',
        offerActivityID: formId,
        referrerSource: 'smart',
      },
    })

    // ACS tracking code (GA4)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'page.submit',
      'page.submit': {
        v: 12,
        title: document.title,
        product: dataProduct,
        done: 'true',
        id: formId,
      },
    })

    // Mutiny tracking code
    window.mutiny = window.mutiny || []
    window.mutiny?.client?.trackConversion &&
      window.mutiny.client.trackConversion({ name: 'form-module' })

    resolve()
  })
}

export default trackFormSubmission
