import React from 'react'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import GatsbyBackgroundImage from 'gatsby-background-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import ACC_THEME from '@themes'

const BackgroundImage = (props) => {
  const { image, className, style, ...otherProps } = props
  const tablet = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const mobile = useMediaQuery(ACC_THEME.breakpoints.down('xs'))
  const imageAsset =
    mobile && image.mobileImage?.filename
      ? image.mobileImage.filename
      : tablet && image.tabletImage?.filename
      ? image.tabletImage.filename
      : image.image || image

  const isSVG =
    (imageAsset && imageAsset.indexOf('.svg') === imageAsset.length - 4) ||
    false

  const prepareImageUrl = (path) =>
    path.replace('https:', '').replace('http:', '')

  const fluidProps = getFluidGatsbyImage(prepareImageUrl(imageAsset), {
    maxWidth: props.maxWidth || 1600,
  })

  return isSVG ? (
    <Box
      className={className}
      style={{
        backgroundImage: `url(${imageAsset})`,
        backgroundRepeat: 'no-repeat',
        ...style,
      }}
      {...otherProps}
    >
      {props.children}
    </Box>
  ) : (
    <GatsbyBackgroundImage
      className={className}
      fluid={fluidProps}
      {...otherProps}
    >
      {props.children}
    </GatsbyBackgroundImage>
  )
}

export default BackgroundImage
