// Takes in a length of a number of items in a grid and then pseudo-intelligently
// applies a width (to be used with flexbox) that implies the number of rows and
//  columns the grid should display at for that number of items. 1 through 5
//  will all display on one row but, for instance, 6 will be 2 rows of 3, and
//  9 will be 3 rows of 3.

const lengthToWidth = {
  0: 0.0,
  1: 100.0,
  2: 50.0,
  3: 33.33333333,
  4: 25.0,
  5: 20.0,
  6: 33.33333333,
  7: 25.0,
  8: 25.0,
  9: 33.33333333,
  10: 20.0,
  11: 25.0,
  12: 25.0,
}

const fluidWidth = (length, spacing = 0.0) =>
  `${length ? lengthToWidth[length] - spacing : 25 - spacing}%`

export default fluidWidth
