import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import { Text, TextSm, H6, Img } from '@system'
import { makeStyles } from '@material-ui/styles'
import addPrecedingSlash from '@helpers/add-preceding-slash'
import PageContext from '@context'
import get from 'lodash/get'
import formatLocalizedLink from '@helpers/format-localized-link'
import useStringTranslation from '@hooks/use-string-translation'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '15px',
    display: 'block',
  },
  description: {
    color: theme.palette.text.tertiary,
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '15px',
    },
    display: 'block',
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    textTransform: 'capitalize',
    marginTop: 'auto',
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: 'auto',
      textTransform: 'uppercase',
    },
    display: 'flex',
  },
  resourceCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: '',
    border: `1px solid ${theme.palette.background.paper}`,
    '& .gatsby-image-wrapper': {
      height: '125px !important',
      '& img': {
        objectFit: 'cover !important',
        objectPosition: 'top !important',
      },
      minHeight: '125px !important',
    },
  },
  resourceCardDetails: {
    padding: '15px 20px',
    flex: '1 0 auto',
  },
  cardLinks: {
    padding: '0px 20px 14px',
    textTransform: 'capitalize',
  },
  blogCardImage: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    height: '125px',
    objectFit: 'cover',
  },
  episodeNumber: {
    padding: '5px 0px',
    color: theme.palette.primary.main,
  },
}))

const ResourceCard = (props) => {
  const pageContext = useContext(PageContext)
  const {
    blok: {
      description,
      episodeNumber,
      format,
      image,
      isWebinarsModule,
      link,
      media,
      target,
      textToHyperlink,
      title,
    },
    imgMaxWidth,
  } = props

  const classes = useStyles()

  const blogImage = media && media.thumbnail.attrs.url

  const { webinars, resources } = pageContext

  const webinarContentTitle =
    webinars && webinars.filter((webinar) => webinar.content.title === title)

  const resourceContentTitle =
    resources &&
    resources.filter((resource) => resource.content.title === title)

  const slug = isWebinarsModule
    ? get(webinarContentTitle, '[0].slug')
    : get(resourceContentTitle, '[0].slug')

  const toPage =
    slug ||
    (!!title &&
      title
        .trim()
        .replace(/[^a-zA-Z0-9]/g, '-')
        .toLowerCase())

  const linkToPage = link
    ? addPrecedingSlash(link)
    : `/${isWebinarsModule ? 'webinars' : 'resources'}/${toPage}`

  const linkText =
    textToHyperlink || (format === 'Video' ? 'Watch' : 'Download')

  const getDescriptionText = (text) => {
    return text.length > 100 ? text.substr(0, 120).concat('.....') : text
  }

  return (
    <Box container="true" className={classes.resourceCard}>
      {image && image.length > 0 ? (
        <Img
          doNotScale={true}
          maxWidth={imgMaxWidth || 1190}
          src={get(image, '[0].image')}
        />
      ) : (
        <img src={blogImage} className={classes.blogCardImage} />
      )}
      <Box className={classes.resourceCardDetails}>
        {!!episodeNumber && (
          <H6 className={classes.episodeNumber}>{episodeNumber}</H6>
        )}
        <Text className={classes.title}>{title}</Text>
        <TextSm className={classes.description}>
          {!!description && getDescriptionText(description)}
        </TextSm>
      </Box>
      <Box className={classes.cardLinks}>
        <TextSm component="span" className={classes.link}>
          <a href={formatLocalizedLink(linkToPage)} target={target}>
            {useStringTranslation(linkText || '')}
          </a>
        </TextSm>
      </Box>
    </Box>
  )
}

export default ResourceCard
