import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    '&::before': {
      content: 'open-quote',
      position: 'relative',
      left: '-0.1em',
      marginLeft: '-0.35em',
    },
    '&::after': {
      content: 'close-quote',
    },
  },
})

const HangingQuote = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default HangingQuote
