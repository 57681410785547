import font from '@helpers/font'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBloks from '@renderBloks'
import { H3, Img } from '@system'
import classNames from 'classnames'
import get from 'lodash/get'
import React from 'react'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  logo: ({ textAlign, productName, fontSize, logoColor }) => ({
    color: theme.palette.common[logoColor || 'black'],
    paddingTop: '0px',
    textAlign: textAlign || 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: textAlign === 'center' ? 'center' : 'flex-start',
    ...makeResponsiveFontSizes(
      // proportional font size reduction for name longer than 25 characters
      (productName.length <= 25 ? 1 : 25 / productName.length) *
        (fontSize || 35)
    ),
    width: '100%',
  }),
  productCard: ({ isNav }) => ({
    minHeight: isNav ? 'initial' : '50px',
    height: 'initial',
    paddingBottom: '40px',
  }),
  image: ({ backgroundColor }) => ({
    alignItems: 'center',
    backgroundColor: backgroundColor?.color || 'none',
    display: 'flex',
  }),
  outerContainer: ({
    doesIncludeAutodesk,
    fontSize,
    logoColor,
    isProductCard,
    shouldAppendAutodesk,
  }) => ({
    color: theme.palette.common[logoColor || 'black'],
    fontFamily: 'Artifakt Legend !important',
    margin: 0,
    fontWeight: doesIncludeAutodesk || shouldAppendAutodesk ? 400 : 700,
    fontSize: 'inherit',
    display: isProductCard ? 'inline-block' : 'flex',
    flexDirection: !isProductCard && 'row',
    flexWrap: !isProductCard && 'wrap',
    alignItems: !isProductCard && 'flex-start',
    ...makeResponsiveFontSizes(
      // proportional font size reduction for name longer than 14 characters
      fontSize * 8 || 35
    ),
    '& img': {
      maxHeight: '70px',
    },
  }),
  autodeskContainer: ({ isProductCard }) => ({
    position: 'relative',
    marginRight: !isProductCard && '17px',
    display: isProductCard && 'inline-block',
  }),
  trademark: ({ fontSize }) => ({
    position: 'absolute',
    top: '17%',
    right: '-7px',
    ...makeResponsiveFontSizes(fontSize * 1.5 || 10, 5),
  }),
  productName: ({
    isProductCard,
    doesIncludeAutodesk,
    productName,
    fontSize,
    firstProductName,
  }) => ({
    margin: 0,
    fontWeight: 700,
    fontSize: 'inherit',
    whiteSpace: doesIncludeAutodesk && isProductCard ? 'nowrap' : 'wrap',
    zIndex: '1',
    ...makeResponsiveFontSizes(
      /* proportional font size reduction for name
       longer than 14 characters and 16 character in case when product name include "Autodesk"
      --> firstProductName check applied only on "BuildingConnected" case
      in "BuildingConnected case it take single string as a productName and firstProductName comes as a null*/
      ((doesIncludeAutodesk || !!firstProductName) && productName.length > 16
        ? 14 / 18
        : 1) * (fontSize * 8 || 35)
    ),
  }),
  isNavLogo: {
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem!important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.246rem!important',
    },
  },
  hiddenHeaderTag: {
    height: '1px',
    margin: 0,
    opacity: 0,
    position: 'absolute',
    width: '1px',
  },
}))

const AutodeskProductLogo = ({
  backgroundColor,
  color,
  fontSize,
  hasPrecedingLogo,
  isH1ForPage,
  isNav,
  isProductCard,
  productLogoImage,
  productLogoName,
  shouldAppendAutodesk,
  textAlign,
}) => {
  const logoColors = { light: 'white', dark: 'black' }
  const logoColor = logoColors[color]

  const name =
    isProductCard && productLogoName.toUpperCase() === 'BUILDINGCONNECTED'
      ? 'BUILDING CONNECTED'
      : productLogoName && productLogoName !== ''
      ? productLogoName.toUpperCase()
      : ' '

  const doesIncludeAutodesk = !!name && name.indexOf('AUTODESK') !== -1

  const splittedNameArrayLength = name.split(' ').length
  const totalNumberOfSpaceCount = parseInt(
    splittedNameArrayLength === 2 ? 1 : splittedNameArrayLength / 2
  )

  let convertedArrayOfName = !!name && name.split(' ')

  let firstProductName = convertedArrayOfName
    .slice(0, totalNumberOfSpaceCount)
    .join(' ')

  let secondProductName = convertedArrayOfName
    .slice(totalNumberOfSpaceCount)
    .join(' ')

  const productName = doesIncludeAutodesk
    ? name.slice(9, name.length)
    : totalNumberOfSpaceCount >= 1 && !doesIncludeAutodesk
    ? secondProductName
    : name

  const classes = useStyles({
    backgroundColor,
    doesIncludeAutodesk,
    firstProductName,
    fontSize,
    isNav,
    isProductCard,
    logoColor,
    productLogoImage,
    productName,
    shouldAppendAutodesk,
    textAlign,
  })

  const ProductLogo = () => (
    <span
      className={classNames(classes.productName, {
        [classes.isNavLogo]: isNav,
      })}
    >
      {productName}
    </span>
  )

  const getProductIcon = (label) => {
    switch (label) {
      case 'AUTODESK BUILD':
        return {
          label: 'AutodeskBuildIcon',
          imgPath:
            'https://a.storyblok.com/f/64835/x/f8c885e354/autodesk-build.svg',
        }
      case 'AUTODESK TAKEOFF':
        return {
          label: 'AutodeskTakeoffIcon',
          imgPath:
            'https://a.storyblok.com/f/64835/x/8f6d0c0fbb/adsk_takeoff.svg',
        }
      case 'AUTODESK BIM COLLABORATE':
        return {
          label: 'CollaborateIcon',
          imgPath:
            'https://a.storyblok.com/f/64835/x/e2eef67d5f/autodesk-bim-collaborate.svg',
        }
      case 'AUTODESK DOCS':
        return {
          label: 'AutodeskDocsIcon',
          imgPath: 'https://a.storyblok.com/f/64835/x/b1aac9f117/adsk_docs.svg',
        }
      case 'AUTODESK CONNECT':
        return {
          label: 'AutodeskConnectIcon',
          imgPath:
            'https://a.storyblok.com/f/64835/x/067101cfcd/adsk_connect.svg',
        }
      case 'PYPE':
        return {
          label: 'PypeIcon',
          imgPath: 'https://a.storyblok.com/f/64835/x/1737175a55/pype.svg',
        }
      case 'ASSEMBLE':
        return {
          label: 'AssembleIcon',
          imgPath: '//a.storyblok.com/f/64835/x/2b5d477d2e/assemble-icon.svg',
        }
      case 'BIM360':
        return {
          label: 'BIM360Icon',
          imgPath: '//a.storyblok.com/f/64835/x/73c8fdc944/bim360-icon.svg',
        }
      case 'BUILDINGCONNECTED':
        return {
          label: 'BuildingConnectedIcon',
          imgPath:
            '//a.storyblok.com/f/64835/x/93e65bc3c0/buildingconnected-icon.svg',
        }
      case 'PLANGRID':
        return {
          label: 'PlanGridIcon',
          imgPath: '//a.storyblok.com/f/64835/x/3e43912148/plangrid-icon.svg',
        }
    }
  }

  const productIcon = getProductIcon(name) && getProductIcon(name).imgPath

  return (
    <>
      {/* render header text to be crawled */}
      {isH1ForPage && !!productLogoImage ? (
        <h1 className={classes.hiddenHeaderTag}>
          {get(productLogoImage, '[0].altText')}{' '}
        </h1>
      ) : (
        <>
          {!!productName && (
            <h3 className={classes.hiddenHeaderTag}>{productName}</h3>
          )}
        </>
      )}
      {/* render product logo */}
      <Box className={classes.image}>
        {!!hasPrecedingLogo && productIcon && <Img src={productIcon} />}
        <Box
          id="product-logo"
          className={classNames(classes.logo, {
            [classes.productCard]: isProductCard,
          })}
        >
          <H3 component="div" className={classes.outerContainer}>
            {!!productLogoImage && <Box>{renderBloks(productLogoImage)}</Box>}
            {doesIncludeAutodesk || shouldAppendAutodesk ? (
              <Box className={classes.autodeskContainer}>
                AUTODESK
                <span className={classes.trademark}>&#174;</span>{' '}
              </Box>
            ) : (
              <>{totalNumberOfSpaceCount >= 1 && firstProductName}</>
            )}
            {isProductCard ? (
              <Box>
                <ProductLogo />
              </Box>
            ) : (
              <ProductLogo />
            )}
          </H3>
        </Box>
      </Box>
    </>
  )
}

export default AutodeskProductLogo
