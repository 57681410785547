const removeTrailingSlash = uri => {
  String.prototype.splice = function(index, count, add) {
    if (index < 0) {
      index = this.length + index
      if (index < 0) {
        index = 0
      }
    }
    return this.slice(0, index) + (add || '') + this.slice(index + count)
  }

  if (!uri) {
    return ''
  }
  return uri.trim()[uri.trim().length - 1] !== '/'
    ? uri
    : uri.splice(uri.lastIndexOf('/'), 1)
}

export default removeTrailingSlash
