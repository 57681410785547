import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import multilinkToUrl from '@helpers/multilink-to-url'

const Link = (props) => {
  const { link } = props

  const isStory = link && link.linktype === 'story'

  const href = link && multilinkToUrl(link)

  const internal = /^\/(?!\/)/.test(href) || isStory

  if (internal) {
    return (
      <GatsbyLink
        to={href}
        activeClassName="active"
        className={props.linkStyle}
        partiallyActive={false}
      >
        {props.children}
      </GatsbyLink>
    )
  }

  const external = /(^((http|https|):\/\/)|(^construction.autodesk))/.test(href)

  if (external) {
    return (
      <a
        href={href}
        className={props.linkStyle}
        target="_BLANK"
        rel="noreferrer"
      >
        {props.children}
      </a>
    )
  }

  return (
    <a href={href} className={props.linkStyle}>
      {props.children}
    </a>
  )
}

export default Link
