import React, { useState } from 'react'
import ScreenshotsGallery from './screenshotsGallery'
import { Box } from '@material-ui/core'
import ScreenshotsPreview from './screenshots-preview'
import get from 'lodash/get'

const VideoScreenshotsPreview = props => {
  const [previewScreenshotUrl, setPreviewScreenshotUrl] = useState(null)

  const toggleScreenshotPreview = url => {
    setPreviewScreenshotUrl(url)
  }

  const previewScreenshotVideo = previewScreenshotUrl
    ? previewScreenshotUrl
    : props.screenshots &&
      props.screenshots[get(Object.keys(props.screenshots), '[0]')]
  return (
    <Box>
      {previewScreenshotVideo && (
        <ScreenshotsPreview image={previewScreenshotVideo} />
      )}
      {props.screenshots && (
        <ScreenshotsGallery
          screenshots={props.screenshots}
          toggleScreenshotPreview={toggleScreenshotPreview}
        />
      )}
    </Box>
  )
}

export default VideoScreenshotsPreview
