import React from 'react'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import font from '@helpers/font'
import ACC_THEME from '@themes'

const { breakpointValues } = font
const { xl, lg, md, sm, xs } = breakpointValues

const useStyles = makeStyles(() => ({
  root: ({ noLeft, noRight, styles }) => ({
    // Removing the right or left margins adds that amount to the max-width
    maxWidth: noLeft || noRight ? xs + (sm - xs) / 2 : xs,
    marginLeft: noLeft ? 0 : 'auto',
    marginRight: noRight ? 0 : 'auto',
    [ACC_THEME.breakpoints.up('sm')]: {
      maxWidth: noLeft || noRight ? sm + (md - sm) / 2 : sm,
    },
    [ACC_THEME.breakpoints.up('md')]: {
      maxWidth: noLeft || noRight ? md + (lg - md) / 2 : md,
    },
    [ACC_THEME.breakpoints.up('lg')]: {
      maxWidth: noLeft || noRight ? lg + (xl - lg) / 2 : lg,
    },
    ...styles,
  }),
}))

const ContentContainer = (props) => {
  // This module constrains children to the next breakpoint down
  // Pass prop 'parentWidth' to return content un-constrained
  // Pass props 'noRight' or 'noLeft' to REMOVE margin from that side, as with the two-column-highlights-with-image module
  const classes = useStyles(props)

  return props.parentWidth ? (
    props.children
  ) : (
    <Container fixed className={classes.root} {...props}>
      {props.children}
    </Container>
  )
}

export default ContentContainer
