import font from './font'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const {
  breakpointValues,
  breakpointValues: { xs, sm, md, lg, xl },
} = font

const makeBreakpointPadding = ({ noLeft = null, noRight = null } = {}) => {
  const breakpoints = createBreakpoints({ values: breakpointValues })
  const calcPadding = (bp) =>
    `0
    ${noRight ? '0' : `calc((100vw - ${bp}px ) / 2 + 24px)`}
    0
    ${noLeft ? '0' : `calc((100vw - ${bp}px ) / 2 + 24px)`}`

  return {
    [breakpoints.up('xs')]: {
      padding: calcPadding(sm),
    },
    [breakpoints.up('sm')]: {
      padding: calcPadding(md),
    },
    [breakpoints.up('md')]: {
      padding: calcPadding(lg),
    },
    [breakpoints.up('lg')]: {
      padding: calcPadding(lg),
    },
    [breakpoints.up('xl')]: {
      padding: `0
        ${noRight ? '0' : `${(xl - lg + 48) / 2}px`}
        0
        ${noLeft ? '0' : `${(xl - lg + 48) / 2}px`}`,
    },
  }
}

export default makeBreakpointPadding
