import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import renderBlok from '@renderBlok'

const useStyle = makeStyles(() => ({
  socialLinks: {
    '& a': {
      display: 'block',
      '&:hover': {
        borderBottom: 'none',
      },
      '& img': {
        display: 'block',
        height: '24px',
        objectPosition: 'center',
        width: '24px !important',
      },
    },
  },
}))

const SocialLinks = (props) => {
  const classes = useStyle()

  return (
    <Grid container item direction="row" md={5} lg={4}>
      {!!props.socialLinkItems.socialLinks &&
        props.socialLinkItems.socialLinks.map((socialLink) => (
          <Box className={classes.socialLinks} m="6px" key={socialLink._uid}>
            {renderBlok(socialLink)}
          </Box>
        ))}
    </Grid>
  )
}

export default SocialLinks
