import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import font from '../helpers/font'
const { breakpointValues } = font

const BOTTOM_NAV = {
  breakpoints: {
    values: breakpointValues,
  },
  typography: {
    fontFamily: 'Artifakt Element, Artifakt, Arial, sans-serif',
    fontWeight: 700,
  },
  palette: {
    type: 'dark',
    common: {
      black: '#000002',
      white: '#fff',
    },
    primary: {
      main: '#1858A8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0696D7',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#D77304',
      contrastText: '#fff',
    },
    quaternary: {
      main: '#009348',
      contrastText: '#fff',
    },
    quinary: {
      main: '#D7DF23',
      contrastText: '#323232',
    },
    senary: {
      main: '#FDA11A',
      contrastText: '#323232',
    },
    error: {
      main: '#de0085',
      contrastText: '#fff',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 225, 225, 0.7)',
      tertiary: '#000002',
      disabled: '#B1B1B1',
      hint: 'rgba(255, 225, 225, 0.5)',
      icon: 'rgba(255, 225, 225, 0.5)',
      headline: '#4589BC',
      font: '#666666',
      white: '#FFF',
      dark: '#999999',
    },
    background: {
      primary: '#1858A8',
      paper: '#E4E4E4',
      slate: '#666666',
      dark: '#999999',
      slite: '#F5F5F5',
      default: '#000002',
    },
    action: {
      active: '#000',
      hover: 'rgba(0, 0, 2, 0.1)',
      hoverOpacity: 0.1,
      selected: 'rgba(0, 0, 2, 0.2)',
      disabled: 'rgba(0, 0, 2, 0.3)',
      disabledBackground: 'rgba(0, 0, 2, 0.12)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#666666',
        },
      },
    },
  },
}

export default responsiveFontSizes(createTheme(BOTTOM_NAV), {
  disableAlign: true,
  factor: 4,
})
