import ArtifaktLegendLightEOT from './fontfiles/artifakt-legend-light.eot?#iefix'
import ArtifaktLegendLightWOFF2 from './fontfiles/artifakt-legend-light.woff2'
import ArtifaktLegendLightWOFF from './fontfiles/artifakt-legend-light.woff'
import ArtifaktLegendLightTTF from './fontfiles/artifakt-legend-light.ttf'
import ArtifaktLegendLightSVG from './fontfiles/artifakt-legend-light.svg'

import ArtifaktLegendRegularEOT from './fontfiles/artifakt-legend-regular.eot?#iefix'
import ArtifaktLegendRegularWOFF2 from './fontfiles/artifakt-legend-regular.woff2'
import ArtifaktLegendRegularWOFF from './fontfiles/artifakt-legend-regular.woff'
import ArtifaktLegendRegularTTF from './fontfiles/artifakt-legend-regular.ttf'
import ArtifaktLegendRegularSVG from './fontfiles/artifakt-legend-regular.svg'

import ArtifaktLegendMediumEOT from './fontfiles/artifakt-legend-medium.eot?#iefix'
import ArtifaktLegendMediumWOFF2 from './fontfiles/artifakt-legend-medium.woff2'
import ArtifaktLegendMediumWOFF from './fontfiles/artifakt-legend-medium.woff'
import ArtifaktLegendMediumTTF from './fontfiles/artifakt-legend-medium.ttf'
import ArtifaktLegendMediumSVG from './fontfiles/artifakt-legend-medium.svg'

import ArtifaktLegendBoldEOT from './fontfiles/artifakt-legend-bold.eot?#iefix'
import ArtifaktLegendBoldWOFF2 from './fontfiles/artifakt-legend-bold.woff2'
import ArtifaktLegendBoldWOFF from './fontfiles/artifakt-legend-bold.woff'
import ArtifaktLegendBoldTTF from './fontfiles/artifakt-legend-bold.ttf'
import ArtifaktLegendBoldSVG from './fontfiles/artifakt-legend-bold.svg'

import ArtifaktElementLightEOT from './fontfiles/artifakt-element-light.eot?#iefix'
import ArtifaktElementLightWOFF2 from './fontfiles/artifakt-element-light.woff2'
import ArtifaktElementLightWOFF from './fontfiles/artifakt-element-light.woff'
import ArtifaktElementLightTTF from './fontfiles/artifakt-element-light.ttf'
import ArtifaktElementLightSVG from './fontfiles/artifakt-element-light.svg'

import ArtifaktElementRegularEOT from './fontfiles/artifakt-element-regular.eot?#iefix'
import ArtifaktElementRegularWOFF2 from './fontfiles/artifakt-element-regular.woff2'
import ArtifaktElementRegularWOFF from './fontfiles/artifakt-element-regular.woff'
import ArtifaktElementRegularTTF from './fontfiles/artifakt-element-regular.ttf'
import ArtifaktElementRegularSVG from './fontfiles/artifakt-element-regular.svg'

import ArtifaktElementMediumEOT from './fontfiles/artifakt-element-medium.eot?#iefix'
import ArtifaktElementMediumWOFF2 from './fontfiles/artifakt-element-medium.woff2'
import ArtifaktElementMediumWOFF from './fontfiles/artifakt-element-medium.woff'
import ArtifaktElementMediumTTF from './fontfiles/artifakt-element-medium.ttf'
import ArtifaktElementMediumSVG from './fontfiles/artifakt-element-medium.svg'

import ArtifaktElementItalicEOT from './fontfiles/artifakt-element-italic.eot?#iefix'
import ArtifaktElementItalicWOFF2 from './fontfiles/artifakt-element-italic.woff2'
import ArtifaktElementItalicWOFF from './fontfiles/artifakt-element-italic.woff'
import ArtifaktElementItalicTTF from './fontfiles/artifakt-element-italic.ttf'
import ArtifaktElementItalicSVG from './fontfiles/artifakt-element-italic.svg'

import ArtifaktElementBoldEOT from './fontfiles/artifakt-element-bold.eot?#iefix'
import ArtifaktElementBoldWOFF2 from './fontfiles/artifakt-element-bold.woff2'
import ArtifaktElementBoldWOFF from './fontfiles/artifakt-element-bold.woff'
import ArtifaktElementBoldTTF from './fontfiles/artifakt-element-bold.ttf'
import ArtifaktElementBoldSVG from './fontfiles/artifakt-element-bold.svg'

import ArtifaktElementBlackEOT from './fontfiles/artifakt-element-black.eot?#iefix'
import ArtifaktElementBlackWOFF2 from './fontfiles/artifakt-element-black.woff2'
import ArtifaktElementBlackWOFF from './fontfiles/artifakt-element-black.woff'
import ArtifaktElementBlackTTF from './fontfiles/artifakt-element-black.ttf'
import ArtifaktElementBlackSVG from './fontfiles/artifakt-element-black.svg'

const artifaktFontFaces = [
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementLightEOT}) format('eot'),
    url(${ArtifaktElementLightWOFF2}) format('woff2'),
    url(${ArtifaktElementLightWOFF}) format('woff'),
    url(${ArtifaktElementLightTTF}) format('truetype'),
    url(${ArtifaktElementLightSVG}#ArtifaktElementLight) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 300,
  },
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementRegularEOT}) format('eot'),
    url(${ArtifaktElementRegularWOFF2}) format('woff2'),
    url(${ArtifaktElementRegularWOFF}) format('woff'),
    url(${ArtifaktElementRegularTTF}) format('truetype'),
    url(${ArtifaktElementRegularSVG}#ArtifaktElementRegular) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 400,
  },
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementMediumEOT}) format('eot'),
    url(${ArtifaktElementMediumWOFF2}) format('woff2'),
    url(${ArtifaktElementMediumWOFF}) format('woff'),
    url(${ArtifaktElementMediumTTF}) format('truetype'),
    url(${ArtifaktElementMediumSVG}#ArtifaktElementMedium) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 500,
  },
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementBoldEOT}) format('eot'),
    url(${ArtifaktElementBoldWOFF2}) format('woff2'),
    url(${ArtifaktElementBoldWOFF}) format('woff'),
    url(${ArtifaktElementBoldTTF}) format('truetype'),
    url(${ArtifaktElementBoldSVG}#ArtifaktElementBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 700,
  },
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementBoldEOT}) format('eot'),
    url(${ArtifaktElementBoldWOFF2}) format('woff2'),
    url(${ArtifaktElementBoldWOFF}) format('woff'),
    url(${ArtifaktElementBoldTTF}) format('truetype'),
    url(${ArtifaktElementBoldSVG}#ArtifaktElementBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 'bold',
  },
  {
    fontFamily: 'Artifakt',
    src: `
    url(${ArtifaktElementBlackEOT}) format('eot'),
    url(${ArtifaktElementBlackWOFF2}) format('woff2'),
    url(${ArtifaktElementBlackWOFF}) format('woff'),
    url(${ArtifaktElementBlackTTF}) format('truetype'),
    url(${ArtifaktElementBlackSVG}#ArtifaktElementBlack) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 800,
  },
  {
    fontFamily: 'Artifakt Legend',
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 300,
    src: `
      url(${ArtifaktLegendLightEOT}),
      url(${ArtifaktLegendLightEOT}) format('eot'),
      url(${ArtifaktLegendLightWOFF2}) format('woff2'),
      url(${ArtifaktLegendLightWOFF}) format('woff'),
      url(${ArtifaktLegendLightTTF}) format('truetype'),
      url(${ArtifaktLegendLightSVG}#ArtifaktLegendLight) format('svg');
    `,
  },
  {
    fontFamily: 'Artifakt Legend',
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 400,
    src: `
      url(${ArtifaktLegendRegularEOT}),
      url(${ArtifaktLegendRegularEOT}) format('eot'),
      url(${ArtifaktLegendRegularWOFF2}) format('woff2'),
      url(${ArtifaktLegendRegularWOFF}) format('woff'),
      url(${ArtifaktLegendRegularTTF}) format('truetype'),
      url(${ArtifaktLegendRegularSVG}#ArtifaktLegendRegular) format('svg');
    `,
  },
  {
    fontFamily: 'Artifakt Legend',
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 500,
    src: `
      url(${ArtifaktLegendMediumEOT}),
      url(${ArtifaktLegendMediumEOT}) format('eot'),
      url(${ArtifaktLegendMediumWOFF2}) format('woff2'),
      url(${ArtifaktLegendMediumWOFF}) format('woff'),
      url(${ArtifaktLegendMediumTTF}) format('truetype'),
      url(${ArtifaktLegendMediumSVG}#ArtifaktLegendMedium) format('svg');
    `,
  },
  {
    fontFamily: 'Artifakt Legend',
    src: `
    url(${ArtifaktLegendBoldEOT}) format('eot'),
    url(${ArtifaktLegendBoldWOFF2}) format('woff2'),
    url(${ArtifaktLegendBoldWOFF}) format('woff'),
    url(${ArtifaktLegendBoldTTF}) format('truetype'),
    url(${ArtifaktLegendBoldSVG}#ArtifaktLegendBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 700,
  },
  {
    fontFamily: 'Artifakt Legend',
    src: `
    url(${ArtifaktLegendBoldEOT}) format('eot'),
    url(${ArtifaktLegendBoldWOFF2}) format('woff2'),
    url(${ArtifaktLegendBoldWOFF}) format('woff'),
    url(${ArtifaktLegendBoldTTF}) format('truetype'),
    url(${ArtifaktLegendBoldSVG}#ArtifaktLegendBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 'bold',
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementLightEOT}) format('eot'),
    url(${ArtifaktElementLightWOFF2}) format('woff2'),
    url(${ArtifaktElementLightWOFF}) format('woff'),
    url(${ArtifaktElementLightTTF}) format('truetype'),
    url(${ArtifaktElementLightSVG}#ArtifaktElementLight) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 300,
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementRegularEOT}) format('eot'),
    url(${ArtifaktElementRegularWOFF2}) format('woff2'),
    url(${ArtifaktElementRegularWOFF}) format('woff'),
    url(${ArtifaktElementRegularTTF}) format('truetype'),
    url(${ArtifaktElementRegularSVG}#ArtifaktElementRegular) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 400,
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementMediumEOT}) format('eot'),
    url(${ArtifaktElementMediumWOFF2}) format('woff2'),
    url(${ArtifaktElementMediumWOFF}) format('woff'),
    url(${ArtifaktElementMediumTTF}) format('truetype'),
    url(${ArtifaktElementMediumSVG}#ArtifaktElementMedium) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 500,
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementItalicEOT}) format('eot'),
    url(${ArtifaktElementItalicWOFF2}) format('woff2'),
    url(${ArtifaktElementItalicWOFF}) format('woff'),
    url(${ArtifaktElementItalicTTF}) format('truetype'),
    url(${ArtifaktElementItalicSVG}#ArtifaktElementItalic) format('svg')`,
    fontStyle: 'italic',
    fontDisplay: 'block',
    fontWeight: 400,
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementBoldEOT}) format('eot'),
    url(${ArtifaktElementBoldWOFF2}) format('woff2'),
    url(${ArtifaktElementBoldWOFF}) format('woff'),
    url(${ArtifaktElementBoldTTF}) format('truetype'),
    url(${ArtifaktElementBoldSVG}#ArtifaktElementBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 700,
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementBoldEOT}) format('eot'),
    url(${ArtifaktElementBoldWOFF2}) format('woff2'),
    url(${ArtifaktElementBoldWOFF}) format('woff'),
    url(${ArtifaktElementBoldTTF}) format('truetype'),
    url(${ArtifaktElementBoldSVG}#ArtifaktElementBold) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 'bold',
  },
  {
    fontFamily: 'Artifakt Element',
    src: `
    url(${ArtifaktElementBlackEOT}) format('eot'),
    url(${ArtifaktElementBlackWOFF2}) format('woff2'),
    url(${ArtifaktElementBlackWOFF}) format('woff'),
    url(${ArtifaktElementBlackTTF}) format('truetype'),
    url(${ArtifaktElementBlackSVG}#ArtifaktElementBlack) format('svg')`,
    fontStyle: 'normal',
    fontDisplay: 'block',
    fontWeight: 800,
  },
]

export default artifaktFontFaces
