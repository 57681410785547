import { useStaticQuery, graphql } from 'gatsby'
import { primaryBuildFolder } from '@variables/global-variables'

// Returns translation for provided string if valid
const useStringTranslation = (string) => {
  // queries all datasource entries that have non-null-value localized dimension(s)
  const data = useStaticQuery(graphql`
    query {
      allStoryblokDatasourceEntry(
        filter: {
          data_source: { eq: "localized-strings" }
          dimension_value: { ne: null }
        }
      ) {
        totalCount
        nodes {
          data_source
          data_source_dimension
          dimension_value
          id
          name
          value
        }
      }
    }
  `)

  const dimensions = data.allStoryblokDatasourceEntry?.nodes
    .filter((entry) => entry.data_source_dimension === primaryBuildFolder)
    .reduce((a, curr) => {
      const englishString = curr.value.toLowerCase()
      const translatedString = curr.dimension_value
      a[englishString] = translatedString
      return a
    }, {})
  return dimensions[string.toLowerCase()] || string
}
export default useStringTranslation
