import React from 'react'
import { Box } from '@material-ui/core'
import { Text, TextSm, Img } from '@system'
import { makeStyles } from '@material-ui/styles'
import useStringTranslation from '@hooks/use-string-translation'
import multilinkToUrl from '@helpers/multilink-to-url'
import formatLocalizedLink from '@helpers/format-localized-link'

const useStyles = makeStyles((theme) => ({
  date: {
    padding: '1rem 0 0',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    padding: '.25rem 0 1rem',
    display: 'block',
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    textTransform: 'capitalize',
    marginTop: 'auto',
    display: 'flex',
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
    },
  },
  blogCardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: '',
    '& .gatsby-image-wrapper': {
      height: '125px !important',
      '& img': {
        objectFit: 'cover !important',
        objectPosition: 'top !important',
      },
      minHeight: '125px !important',
    },
  },
  cardDetails: {
    color: theme.palette.primary.main,
    padding: '1rem 0',
    flex: '1 0 auto',
  },
  cardLinks: {
    padding: '0 0 14px',
    textTransform: 'capitalize',
  },
  blogCardImage: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    height: '125px',
    objectFit: 'cover',
  },
}))

const BlogCard = (props) => {
  const {
    link,
    media,
    target,
    textToHyperlink,
    title,
    image,
    imgMaxWidth,
    dateText,
    pubDate,
  } = props

  const classes = useStyles()

  const blogImage = media?.thumbnail?.attrs?.url

  const linkToPage =
    typeof link === 'string' ? formatLocalizedLink(link) : multilinkToUrl(link)

  const getBlogDate = () => {
    if (!pubDate) return ''
    const date = new Date(pubDate)
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear()
    const displayDate = `${month} ${year}`
    return displayDate
  }
  const date = dateText || getBlogDate()

  return (
    <Box container="true" className={classes.blogCardRoot}>
      {image?.filename ? (
        <Img
          doNotScale={true}
          maxWidth={imgMaxWidth || 1190}
          src={image.filename}
        />
      ) : (
        <img
          alt="Blog card Thumbnail"
          src={
            blogImage ||
            'https://a.storyblok.com/f/64835/2880x1202/2f35bb4146/1440x600_acc_hp_hero_awareness_campaign-2x.jpg'
          }
          className={classes.blogCardImage}
        />
      )}
      <Box className={classes.cardDetails}>
        {!!date && <Text className={classes.date}>{date}</Text>}
        <Text className={classes.title}>{title}</Text>
      </Box>
      <Box className={classes.cardLinks}>
        <TextSm component="span" className={classes.link}>
          <a href={linkToPage} target={target}>
            {useStringTranslation(textToHyperlink || 'Read the Blog')}
          </a>
        </TextSm>
      </Box>
    </Box>
  )
}

export default BlogCard
