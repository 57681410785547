import {
  calculateDimension,
  fileIsRetina,
  isRetinaBrowser,
} from '@helpers/images'
import isInBrowser from '@helpers/is-in-browser'
import GatsbyImg from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import React from 'react'

const Image = (props) => {
  const { fluid, fixed, ...otherProps } = props

  const imageSrc =
    !!props.src && props.src.split('/')[props.src.split('/').length - 1]
  const imageAltText =
    !!imageSrc && imageSrc.substr(0, imageSrc.lastIndexOf('.'))

  const prepareImageUrl = (path) =>
    path.replace('https:', '').replace('http:', '')

  const fluidProps = props.src
    ? getFluidGatsbyImage(prepareImageUrl(props.src), {
        maxWidth: calculateDimension({
          value:
            props.maxWidth ||
            (!!props.style && props.style.maxWidth) ||
            (isInBrowser && window.innerWidth) ||
            1920,
          _fileIsRetina: fileIsRetina(props.src),
          _isRetinaBrowser: isRetinaBrowser,
          image: props.src,
          doNotScale: props.doNotScale,
        }),
      })
    : fluid

  const isSVG = props.src
    ? props.src.indexOf('.svg') === props.src.length - 4
    : props.isSVG || false

  const styles = {
    width: '100%',
    objectFit: 'contain',
    ...props.style,
  }

  return isSVG ? (
    <img
      src={props.src}
      title={props.title}
      alt={props.alt || imageAltText}
      style={styles}
    />
  ) : fixed ? (
    <GatsbyImg
      style={styles}
      imgStyle={styles}
      title={props.title}
      alt={props.alt || imageAltText}
      fixed={fixed}
      objectFit={props?.style?.objectFit}
      objectPosition={props?.style?.objectPosition}
      {...otherProps}
    />
  ) : (
    <GatsbyImg
      style={styles}
      title={props.title}
      alt={props.alt || imageAltText}
      objectFit={props?.style?.objectFit}
      objectPosition={props?.style?.objectPosition}
      fluid={fluidProps}
      {...otherProps}
    />
  )
}

export default Image
