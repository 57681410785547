import loadMarketoScript from './load-marketo-script'

function loadMarketoForm(marketoFormId, initCallback) {
  loadMarketoScript().then(_ => {
    if (typeof MktoForms2 !== 'undefined') {
      MktoForms2.loadForm(
        '//constructioncloud.autodesk.com',
        '572-JSV-775',
        marketoFormId,
        initCallback
      )
    }
  })
}

export default loadMarketoForm
