import { allBuildFolders } from '@variables/global-variables'

const locale = new RegExp(
  `^default/|/default/${allBuildFolders.reduce(
    (acc, val) => `${acc}|${val}/|/${val}/`,
    ''
  )}`
)

// Regex matches and replaces any instance of "us/..." , "/us/..." , "gb/...", etc. with "/" to avoid adding our folder structure to relative links
const formatLocalizedLink = (link) => {
  return link.replace(locale, '/')
}

export default formatLocalizedLink
