import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import artifaktFontFaces from '../fonts/artifakt'
import font from '../helpers/font'

const {
  breakpointValues,
  htmlFontSize,
  pxToRem,
  adjustedStartValue,
  makeResponsiveFontSizes,
} = font

const fonts = [
  'Artifakt Element',
  'Artifakt Legend',
  'Artifakt',
  'Arial',
  'sans-serif',
].join(',')

const legacy = {
  primary: '#1858A8',
  secondary: '#0696D7',
  tertiary: '#172E52',
  quinary: '#D7DF23',
}

const ACC_THEME = {
  palette: {
    legacy: legacy,
    common: {
      black: '#000000',
      white: '#fff',
    },
    type: 'light',
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000000',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#000000',
      contrastText: '#fff',
    },
    quaternary: {
      main: '#009348',
      contrastText: '#fff',
    },
    quinary: {
      main: '#fff',
      contrastText: '#000000',
    },
    senary: {
      main: '#FDA11A',
      contrastText: '#323232',
    },
    septenary: {
      main: '#F5F5F5',
      contrastText: '#323232',
    },
    error: {
      main: '#de0085',
      contrastText: '#fff',
    },
    linkWater: '#CAD3D8',
    text: {
      primary: '#000000',
      secondary: '#707070',
      tertiary: '#666666',
      disabled: '#999999',
      hint: 'rgba(0, 0, 2, 0.5)',
      icon: 'rgba(0, 0, 2, 0.5)',
      headline: '#4589BC',
      font: '#666666',
      white: '#fff',
      smoke: '#939393',
      noLink: '#707070',
      bottomNav: '#707070',
      phase: '#C7C6C6',
      activePhase: '#698497',
      placeholder: '#666666',
      matterhorn: '#4C4C4C',
      iris: '#5F60FF',
      plant: '#2BC275',
      grey3: '#333333',
    },
    background: {
      gold: '#FFC21A',
      primary: '#FFF',
      paper: '#E7E7E7',
      slate: '#666666',
      lightSlate: '#CCCCCC',
      lighterSlate: '#F2F2F2',
      dark: '#323232',
      slite: '#F5F5F5',
      default: '#fff',
      clear: 'unset',
      azure: '#f1f2f2',
      bottomNav: '#F4F4F4',
      silver: '#d8d8d8',
      whiteSmoke: '#EFEFEF',
      limerick: '#87B340',
      iris: '#5F60FF',
      lightIris: '#5F60FF7A',
      darkGrey: '#242424',
      grey17: '#171717',
      modal: '#0000007d',
    },
    action: {
      active: '#000',
      hover: 'rgba(0, 0, 2, 0.1)',
      hoverOpacity: 0.1,
      selected: 'rgba(0, 0, 2, 0.2)',
      disabled: '#FFFFFF',
      disabledBackground: '#999999',
    },
    border: {
      gray: '#CCCCCC',
      light: '#e6e6e6',
    },
    gradient: {
      background: 'linear-gradient(90deg, #000000, #262626)',
      sharp: 'linear-gradient(90deg, #000000, #323232)',
    },
  },
  sidebar: {
    backgroundColor: '#1858A8',
    paddingLeft: '44px',
    paddingRight: '44px',
    paddingTop: '34px',
  },
  breakpoints: {
    values: breakpointValues,
  },
  shape: {
    borderRadius: 4,
  },
  navHeight: {
    withSubNav: 136,
    noSubNav: 88,
  },
  // spacing: {},
  // density: {},
  // shadows: [],
  // zIndex: {},
  boxShadow: {
    profileCard: ' 0 3px 15px 4px rgb(0 0 0 / 0.2)',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      fontSize: pxToRem(adjustedStartValue(16)),
    },
  },
  typography: {
    htmlFontSize: htmlFontSize,
    fontFamily: fonts,
    fontSize: adjustedStartValue(20),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    body1: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(20)),
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.4,
      ...makeResponsiveFontSizes(19, 13),
    },
    body2: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(16)),
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.5,
      ...makeResponsiveFontSizes(16, 11),
    },
    h1: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(68)),
      fontWeight: 300,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      ...makeResponsiveFontSizes(68, 24),
    },
    h2: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(119)),
      fontWeight: 300,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      ...makeResponsiveFontSizes(119, 35),
    },
    h3: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(48)),
      fontWeight: 300,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      marginTop: '1rem',
      marginBottom: '1rem',
      ...makeResponsiveFontSizes(48, 18),
    },
    h4: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(31)),
      fontWeight: 400,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      ...makeResponsiveFontSizes(31, 18),
    },
    h5: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(24)),
      fontWeight: 400,
      letterSpacing: '-0.18px',
      lineHeight: 1.2,
      ...makeResponsiveFontSizes(24, 15),
    },
    h6: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(20)),
      fontWeight: 500,
      letterSpacing: '-0.18px',
      lineHeight: 1.5,
      ...makeResponsiveFontSizes(20, 14),
    },
    subtitle1: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(34)),
      fontWeight: 400,
      letterSpacing: '-0.18px',
      lineHeight: 1,
      ...makeResponsiveFontSizes(34, 18),
    },
    caption: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(24)),
      fontWeight: 700,
      letterSpacing: '-0.18px',
      lineHeight: 1,
      ...makeResponsiveFontSizes(24, 16),
    },
    button: {
      fontFamily: fonts,
      fontSize: pxToRem(adjustedStartValue(14)),
      fontWeight: 700,
      letterSpacing: '-0.18px',
      lineHeight: 1,
      textTransform: 'none',
      ...makeResponsiveFontSizes(14, 12),
    },
    MuiTab: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': artifaktFontFaces,
        html: { scrollBehavior: 'smooth' },
        '*::selection': {
          backgroundColor: '#c0ddff',
        },
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: 'rgba(230, 230, 230, 0.3)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
      },
    },
  },
}

const defaultTheme = responsiveFontSizes(createTheme(ACC_THEME), {
  disableAlign: true,
  factor: 4,
})

export { defaultTheme as default, ACC_THEME }
