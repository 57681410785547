import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import PageContext from '@context'
import Box from '@material-ui/core/Box'
import addBothEndSlashes from '@helpers/add-both-end-slashes'
import formatLocalizedLink from '@helpers/format-localized-link'
import { allSiteBuilds, currentBuild } from '@variables/global-variables'
import isInBrowser from '@helpers/is-in-browser'

const Layout = (props) => {
  const [currentDomain, setCurrentDomain] = useState(
    'https://construction.autodesk.com'
  )
  const {
    metaData: {
      title,
      description,
      og_title,
      og_description,
      og_image,
      twitter_title,
      twitter_description,
      twitter_image,
    },
  } = props.metaData
    ? props
    : {
        metaData: {
          title: null,
          description: null,
          og_title: null,
          og_description: null,
          og_image: null,
          twitter_title: null,
          twitter_image: null,
          twitter_description: null,
        },
      }
  const pageContext = useContext(PageContext)
  useEffect(() => {
    if (isInBrowser) {
      setCurrentDomain(window.location.origin)
    }
  }, [setCurrentDomain])

  const baseUrl =
    allSiteBuilds.find(
      (domain) =>
        domain.url === currentDomain || domain.deployUrl === currentDomain
    )?.url || 'https://construction.autodesk.com'

  // Uses .com as x-default alternate link if us build present in alternates, otherwise uses default TLD for current build, i.e. .co.uk for all /gb/ builds
  const defaultLink =
    !pageContext.tagAlternates ||
    pageContext.tagAlternates.some((el) => el.primaryBuildFolder === 'us')
      ? `https://construction.autodesk.com${pageContext?.location?.pathname}`
      : `${currentBuild.url}${pageContext?.location?.pathname}`

  const isAbsmPage =
    !!pageContext?.location?.pathname &&
    pageContext?.location?.pathname.split('/')[1] === 'meet'
  const isLandingPages =
    !!pageContext?.location?.pathname &&
    (pageContext?.location?.pathname.split('/')[1] === 'precon' ||
      pageContext?.location?.pathname.split('/')[1] === 'sitecon' ||
      pageContext?.location?.pathname.split('/')[1] === 'go') &&
    !props.noIndex &&
    !props.canonicalPage

  const robotsTag = !!isLandingPages || !!isAbsmPage || !!props.noIndex

  return (
    <PageContext.Consumer>
      {(context) => (
        <>
          <Helmet>
            {/* General tags */}
            <title>{title || 'Autodesk Construction Cloud'}</title>

            <meta
              name="description"
              content={description || og_description || twitter_description}
            />
            <meta
              name="canonical"
              content={
                !!context && !!context?.location?.pathname
                  ? `${baseUrl}${addBothEndSlashes(
                      context?.location?.pathname
                    )}`
                  : baseUrl
              }
            />
            {robotsTag ? <meta name="robots" content="noindex" /> : null}
            {/* OpenGraph tags */}
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content={og_title || title || twitter_title}
            />
            <meta
              property="og:description"
              content={og_description || description || twitter_description}
            />
            {(twitter_image || og_image) && (
              <meta property="og:image" content={og_image || twitter_image} />
            )}
            <meta
              name="facebook-domain-verification"
              content="5alh61p4i7jsmeh9c9fsibpxsevi02"
            />
            <meta
              name="google-site-verification"
              content="-YUcnZ0ceOirSPtpjWDvpPaSvBT47e-usQfc9ozt7yA"
            />
            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@adsk_construct" />
            <meta name="twitter:author" content="@autodesk" />
            <meta
              name="twitter:title"
              content={twitter_title || title || og_title}
            />
            <meta
              name="twitter:description"
              content={twitter_description || description || og_description}
            />
            {(twitter_image || og_image) && (
              <meta name="twitter:image" content={twitter_image || og_image} />
            )}
            {pageContext?.tagAlternates?.map((tagAlternate) =>
              tagAlternate.hreflangs.map((alternateHreflang) => (
                <link
                  rel="alternate"
                  href={`${tagAlternate.url}${pageContext?.location?.pathname}`}
                  hrefLang={alternateHreflang}
                  key={`alternate-${alternateHreflang}`}
                />
              ))
            )}
            {!props.noIndex &&
              !!props.canonicalPage &&
              props.canonicalPage.full_slug && (
                <link
                  rel="canonical"
                  href={`${baseUrl}${addBothEndSlashes(
                    formatLocalizedLink(props.canonicalPage.full_slug)
                  )}`}
                />
              )}
            <link rel="alternate" href={defaultLink} hrefLang="x-default" />
            <html
              lang={
                currentBuild.buildFolders.includes('us')
                  ? 'en'
                  : currentBuild.hreflangs[0]
              }
            />

            {context?.location?.pathname === '/' && (
              <script type="application/ld+json">
                {`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Autodesk Construction Cloud",
              "url": "https://construction.autodesk.com/",
              "logo": "https://a.storyblok.com/f/64835/x/3ad82df92d/acc-single-line-white-2022.svg",
              "sameAs": [
                "https://www.facebook.com/AutodeskConstructionCloud/",
                "https://twitter.com/adsk_construct",
                "https://www.instagram.com/autodeskconstructioncloud/",
                "https://www.youtube.com/channel/UCzW7BF9OODYHJ3tN6wm55WA",
                "https://www.linkedin.com/company/autodesk-construction-cloud/",
                "https://www.autodesk.com/"
              ]
            }`}
              </script>
            )}

            <meta name="headRosetta" content="true" />
          </Helmet>
          <Box id="top"></Box>
          <Box
            id="page-content"
            mx="auto"
            position="relative"
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            {props.children}
          </Box>
        </>
      )}
    </PageContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
