const getCurrentDateAndTimeInUTC = () => {
  const currentDate = new Date()
  const date = currentDate.getUTCDate()
  const month = currentDate.getUTCMonth() + 1
  const year = currentDate.getUTCFullYear()
  const hour = currentDate.getUTCHours()
  const minutes = currentDate.getUTCMinutes()

  const dateSeparator = '-'
  const timeSeparator = ':'

  const dateFormatter = `${year}${dateSeparator}${
    month < 10 ? `0${month}` : `${month}`
  }${dateSeparator}${date < 10 ? `0${date}` : `${date}`}`

  const timeFormatter = `${hour < 10 ? `0${hour}` : `${hour}`}${timeSeparator}${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }`

  return dateFormatter + ' ' + timeFormatter
}

export default getCurrentDateAndTimeInUTC
