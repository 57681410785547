import Bloks from '@bloks'
import React from 'react'

const renderBlok = (blok, props) => {
  const { webinarSeriesEvents } = blok
  const isWebinarSeries = webinarSeriesEvents?.length > 0

  return React.createElement(
    Bloks(isWebinarSeries ? 'webinarSeries' : blok.component),
    {
      ...props,
      key: blok._uid,
      blok: blok,
    }
  )
}

export default renderBlok
