import loadScript from './load-script'

function loop(resolve, reject) {
  if (typeof MktoForms2 !== 'undefined') {
    resolve()
    console.log('global has loaded')
  } else setTimeout(loop, 100)
}

function loadMarketoScript() {
  return new Promise((resolve, reject) => {
    if (typeof document !== 'undefined') {
      const marketoScriptId = 'marketoScript'

      if (typeof MktoForms2 !== 'undefined') {
        // MktoForms2 is available
        resolve()
      } else if (document.getElementById(marketoScriptId)) {
        // if global MktoForms2 is not available yet (to other forms), wait until loaded
        loop(resolve, reject)
      } else {
        // add marketo script to document
        loadScript(
          '//constructioncloud.autodesk.com/js/forms2/js/forms2.min.js',
          marketoScriptId
        ).then(resolve, reject)
      }
    } else {
      resolve()
    }
  })
}

export default loadMarketoScript
