import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Icon } from '@system'
import ACC_THEME from '@themes'
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  accordionRoot: ({ expanded, highlightColor }) => ({
    backgroundColor:
      highlightColor?.color && !expanded ? highlightColor.color : null,
    border: `1px solid${
      highlightColor?.color ? highlightColor.color : '#C4C4C4'
    }`,
    color:
      highlightColor?.color && !expanded ? '#fff' : theme.palette.common.black,
    flex: 'none',
    position: 'relative',
    width: '100%',
    '& .pricing-toggle': {
      marginTop: '12px',
      width: 'calc(100% - 32px)',
    },
  }),
  accordionRootVariantTable: {
    backgroundColor: '#F4F4F4',
    border: '1px solid #F4F4F4 !important',
  },
  accordionRootVariantTabsModule: ({ expanded }) => ({
    paddingBottom: expanded ? '16px' : null,
    '&:not(:last-of-type)': {
      borderBottom: 'none',
    },
    '& a': {
      alignItems: 'center',
      color: theme.palette.common.black,
      display: 'flex',
      fontSize: '16px',
      '& img': {
        height: '22px',
        width: '22px !important',
      },
      '&:hover': {
        color: theme.palette.common.black,
      },
    },
  }),
  headerContainer: {
    cursor: 'pointer',
    padding: '16px',
    width: '100%',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  titleContainerVariantTable: {
    marginBottom: '0',
  },
  titleContainerVariantTabsModule: {
    marginBottom: '0',
  },
  title: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1',
  },
  titleVariantTable: {
    fontSize: '20px',
  },
  icon: {
    flex: 'none',
    '& svg': {
      display: 'block',
    },
  },
  iconVariantTabsModule: ({ expanded }) => ({
    '& svg': {
      transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
    },
  }),
  previewText: {
    fontSize: '20px',
    textAlign: 'start',
    width: '100%',
  },
}))

const Accordion = ({
  children,
  highlightColor,
  previewText,
  title,
  pricingCardsModuleHeading,
  variant,
}) => {
  const [expanded, setExpanded] = useState(false)
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const classes = useStyles({
    expanded,
    highlightColor,
    previewText,
    title,
    variant,
  })

  const handleClick = () => {
    setExpanded(!expanded)
  }

  // Handle variant class names
  let classesAccordionRoot = [classes.accordionRoot]
  let classesTitleContainer = [classes.titleContainer]
  let classesTitle = [classes.title]
  let classesIcon = [classes.icon]

  if (variant === 'table') {
    classesAccordionRoot.push(classes.accordionRootVariantTable)
    classesTitleContainer.push(classes.titleContainerVariantTable)
    classesTitle.push(classes.titleVariantTable)
  }

  if (variant === 'tabsModule') {
    classesAccordionRoot.push(classes.accordionRootVariantTabsModule)
    classesTitleContainer.push(classes.titleContainerVariantTabsModule)
    classesIcon.push(classes.iconVariantTabsModule)
  }

  if (
    (handHeldDevice && previewText) ||
    (handHeldDevice && variant === 'table') ||
    (handHeldDevice && variant === 'tabsModule')
  ) {
    return (
      <div className={classesAccordionRoot.join(' ')}>
        <div className={classes.headerContainer} onClick={handleClick}>
          <div className={classesTitleContainer.join(' ')}>
            {title ? (
              <div className={classesTitle.join(' ')}>{title}</div>
            ) : (
              <div className={classes.title}>{pricingCardsModuleHeading}</div>
            )}
            <div className={classesIcon.join(' ')}>
              {variant === 'tabsModule' ? (
                <Icon>ChevronRight</Icon>
              ) : (
                <Icon>{expanded ? 'subtract' : 'add'}</Icon>
              )}
            </div>
          </div>
          {expanded ? null : (
            <div className={classes.previewText}>{previewText}</div>
          )}
        </div>
        {expanded ? children : null}
      </div>
    )
  }

  return children
}

export default Accordion
