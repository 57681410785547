import font from '@helpers/font'
const { breakpoints } = font

const columns = {
  0: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 },
  1: { xl: 1, lg: 1, md: 1, sm: 1, xs: 1 },
  2: { xl: 2, lg: 2, md: 2, sm: 2, xs: 1 },
  3: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 },
  4: { xl: 4, lg: 4, md: 2, sm: 2, xs: 1 },
  5: { xl: 5, lg: 3, md: 3, sm: 2, xs: 1 },
  6: { xl: 3, lg: 3, md: 3, sm: 2, xs: 1 },
  7: { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 },
  8: { xl: 4, lg: 4, md: 2, sm: 2, xs: 1 },
  9: { xl: 3, lg: 3, md: 3, sm: 2, xs: 1 },
  10: { xl: 5, lg: 4, md: 2, sm: 2, xs: 1 },
  11: { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 },
  12: { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 },
}

const intelligentColumns = (numberOfItems, overrideColumnWidth) => {
  const calculateWidth = (breakpoint) => {
    return 100 / columns[overrideColumnWidth || numberOfItems || 3][breakpoint]
  }

  return {
    width: `${calculateWidth('xs')}%`,
    [breakpoints.up('sm')]: { width: `${calculateWidth('sm')}%` },
    [breakpoints.up('md')]: { width: `${calculateWidth('md')}%` },
    [breakpoints.up('lg')]: { width: `${calculateWidth('lg')}%` },
    [breakpoints.up('xl')]: { width: `${calculateWidth('xl')}%` },
  }
}

export default intelligentColumns
