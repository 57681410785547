import { useStaticQuery, graphql } from 'gatsby'
import { primaryBuildFolder } from '@variables/global-variables'

// returns all non-null dimension values for localized strings
const useLocalizedStrings = () => {
  // queries all datasource entries that have non-null-value localized dimension(s)
  const data = useStaticQuery(graphql`
    query {
      allStoryblokDatasourceEntry(
        filter: {
          data_source: { eq: "localized-strings" }
          dimension_value: { ne: null }
        }
      ) {
        totalCount
        nodes {
          data_source
          data_source_dimension
          dimension_value
          id
          name
          value
        }
      }
    }
  `)

  // String library obj is returned:
  // {
  //     <String in English(value)>: <dimension value matching locale(dimension_value)>,
  //     <String in English>: <dimension value matching locale>,
  //     ...
  //   }
  // }
  const dimensions = data.allStoryblokDatasourceEntry?.nodes
    .filter((entry) => entry.data_source_dimension === primaryBuildFolder)
    .reduce((a, curr) => {
      const englishString = curr.value.toLowerCase()
      const translatedString = curr.dimension_value
      a[englishString] = translatedString
      return a
    }, {})
  return dimensions
}
export default useLocalizedStrings
