import React, { createContext } from 'react'
import useLocaleByGeoIP from '@hooks/use-locale-by-geo-IP'

const PageContext = createContext()
const { Provider } = PageContext

const PageContextProvider = (props) => {
  const locale = useLocaleByGeoIP()

  return (
    <Provider
      value={{
        ...props,
        locale: locale,
      }}
    >
      {props.children}
    </Provider>
  )
}

export default PageContext
export { PageContextProvider }
