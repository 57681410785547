import React from 'react'
import { Skeleton as MUISkeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(
  {
    root: {
      // padding: '6px',
      overflow: 'hidden',
      backgroundColor: (props) => (props.color && props.color.color) || null,
    },
  },
  { name: 'MuiSkeleton' }
)

const Skeleton = (props) => {
  const { children } = props
  const classes = useStyles(props)
  return (
    <MUISkeleton
      classes={{ root: classes.root }}
      {...props}
      component="div"
      color={props.color && props.color.color}
    >
      {children}
    </MUISkeleton>
  )
}

export default Skeleton
