import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
const breakpointValues = {
  xs: 448,
  sm: 648,
  md: 848,
  lg: 1108,
  xl: 1276,
}
const fontSize = 20 // px
// Tell Material-UI what's the font-size on the html element.
// 16px is the default font-size used by browsers.
const htmlFontSize = 16
const coef = fontSize / 16
const pxToRem = (size) => `${((size / htmlFontSize) * coef).toFixed(3)}rem`
const breakpoints = createBreakpoints({ values: breakpointValues })
const adjustedStartValue = (startValue) =>
  (breakpointValues.xl / 1920) * startValue
const makeResponsiveFontSizes = (startValue, endValue = 16, units = 'px') => {
  const calculate = (breakpoint) => {
    const formula =
      ((adjustedStartValue(startValue) - endValue) *
        breakpoints.values[breakpoint]) /
        breakpoints.values['xl'] +
      endValue
    return units === 'px' ? pxToRem(formula) : formula + units
  }
  return {
    [breakpoints.down('xs')]: { fontSize: calculate('xs') },
    [breakpoints.up('xs')]: { fontSize: calculate('sm') },
    [breakpoints.up('sm')]: { fontSize: calculate('md') },
    [breakpoints.up('md')]: { fontSize: calculate('lg') },
    [breakpoints.up('lg')]: { fontSize: calculate('xl') },
  }
}
export default {
  breakpointValues,
  fontSize,
  htmlFontSize,
  coef,
  pxToRem,
  breakpoints,
  adjustedStartValue,
  makeResponsiveFontSizes,
}
