import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { isInBrowser } from '@helpers'
import formatLocalizedLink from '@helpers/format-localized-link'

const GoogleStructuredData = ({ children, type, isAccordionFaq, data }) => {
  switch (type) {
    case 'FAQPage': {
      let faqs = isAccordionFaq
        ? children
        : children
            .reduce((agg, section) => [...agg, ...section.faqs.flat()], [])
            .filter((faq) => !faq.disableGoogledStructuredData)
      return (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: faqs.map((faq) => ({
                '@type': 'Question',
                name: faq.question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: faq.answer,
                },
              })),
            })}
          </script>
        </Helmet>
      )
    }

    case 'Course': {
      const isCoursesIndex = data.courses.length > 1
      const courseDetailData = get(data.courses, '[0]')

      const indexFormat = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [
          ...data.courses.map((course, index) => {
            return {
              '@type': 'ListItem',
              position: index + 1,
              item: {
                '@type': 'Course',
                url: `https://construction.autodesk.com${formatLocalizedLink(
                  course.courseUrl?.story?.url || ''
                )}`,
                name: course.title,
                description: course.description,
                provider: {
                  '@type': 'Organization',
                  name: 'Autdodesk Construction Cloud',
                  sameAs: 'https://construction.autodesk.com',
                },
              },
            }
          }),
        ],
      })

      const detailFormat = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Course',
        name: courseDetailData.title,
        description: courseDetailData.description,
        provider: {
          '@type': 'Organization',
          name: 'Autdodesk Construction Cloud',
          sameAs: 'https://construction.autodesk.com',
        },
      })

      return (
        <Helmet>
          <script type="application/ld+json">
            {isCoursesIndex ? indexFormat : detailFormat}
          </script>
        </Helmet>
      )
    }

    case 'Event': {
      const {
        title,
        description,
        photos,
        startDate,
        endDate,
        zipCode,
        city,
        country,
        publishedAt,
      } = data
      return (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: title,
              description: description,
              image: `http:${get(photos, '[0].image')}`,
              startDate:
                (startDate || publishedAt) &&
                new Date(startDate || publishedAt).toISOString(),
              endDate: endDate && new Date(endDate).toISOString(),
              eventStatus: 'https://schema.org/EventScheduled',
              eventAttendanceMode:
                'https://schema.org/OnlineEventAttendanceMode',
              location: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  postalCode: zipCode,
                  addressLocality: city,
                  addressCountry: country,
                },
              },
            })}
          </script>
        </Helmet>
      )
    }
    case 'Webinar': {
      const {
        image,
        title,
        description,
        startDate,
        speakers,
        metadata,
        duration,
        publishedAt,
      } = data

      const safeDuration = duration ? duration : 60
      const startTime = startDate
        ? new Date(startDate.replace(/-/g, '/')).getTime()
        : publishedAt
        ? new Date(publishedAt.replace(/-/g, '/')).getTime()
        : null

      const endTime = startTime + parseInt(safeDuration)
      const endDate = new Date(endTime)

      return (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Event',
              name: title || metadata.title,
              description: description,
              image: `http:${get(image, '[0].image')}`,
              startDate: !!startTime && new Date(startTime).toISOString(),
              endDate: !!startTime && new Date(endDate).toISOString(),
              eventStatus: 'https://schema.org/EventScheduled',
              eventAttendanceMode:
                'https://schema.org/OnlineEventAttendanceMode',
              location: {
                '@type': 'VirtualLocation',
                url: isInBrowser && window.location.href,
              },
              performer: speakers.map((speaker) => ({
                '@type': 'Person',
                name: speaker.name,
              })),
            })}
          </script>
        </Helmet>
      )
    }
    default:
      return null
  }
}

export default GoogleStructuredData
