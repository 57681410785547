import React, { useState } from 'react'
import { Box, Container, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H3, Button } from '@system'
import useLocalizedStrings from '@hooks/use-localized-strings'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: '120px 0',
    backgroundColor: theme.palette.background.slite,
    '& form': {
      position: 'relative',
      padding: '30px 0px',
      margin: 'auto 0',
      minHeight: '200px',
      '& button': {
        marginLeft: '15px',
        ...theme.typography.button,
        color: theme.palette.text.white,
        background: theme.palette.primary.main,
        boxShadow: 'none',
        padding: '10px 16px',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: '0',
        },
      },
    },
  },
  title: {
    color: theme.palette.secondary.main,
    marginBottom: '30px',
    display: 'block',
  },
  inputFieldContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '15px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%',
      marginBottom: '15px',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  textField: {
    width: '100%',
    '& input': {
      height: '0.4rem',
      padding: '13px 14px',
      fontSize: '0.7rem',
      backgroundColor: theme.palette.common.white,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  textFieldError: {
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
      '&:focus': {
        borderColor: 'red',
        '&:hover': {
          borderColor: 'red',
        },
      },
    },
  },
  errorMessage: {
    color: 'red',
    marginTop: '10px',
  },
}))

const PageValidationForm = (props) => {
  const classes = useStyles()
  const [userEnteredPassword, setPassword] = useState('')
  const [error, setError] = useState('')
  const localizedStrings = useLocalizedStrings()
  const wrongPasswordErrorMessage = 'Wrong Password'
  const emptyFieldErrorMessage = 'Field cannot be empty'
  const pwdPrompt = 'Please enter password to access this page'

  const handleSubmit = (e) => {
    e.preventDefault()

    if (userEnteredPassword.length === 0) {
      setError(
        localizedStrings[emptyFieldErrorMessage.toLowerCase()] ||
          emptyFieldErrorMessage
      )
      return
    }

    const isValidPassword = props.passwords.some(
      (password) => password.value === userEnteredPassword
    )

    if (isValidPassword) props.updateIsPasswordProtected(false)
    else
      setError(
        localizedStrings[wrongPasswordErrorMessage.toLowerCase()] ||
          wrongPasswordErrorMessage
      )
  }

  return (
    <Box className={classes.formContainer}>
      <Container fixed>
        <H3 display="block" className={classes.title}>
          {localizedStrings[pwdPrompt.toLowerCase()] || pwdPrompt}
        </H3>
        <form onSubmit={handleSubmit}>
          <Box className={classes.inputFieldContainer}>
            <TextField
              type="password"
              name="password"
              variant="outlined"
              error={error}
              value={userEnteredPassword}
              onChange={(ev) => setPassword(ev.target.value)}
              className={`${classes.textField} ${
                error && classes.textFieldError
              }`}
            />
            {error && <Box className={classes.errorMessage}>{error}</Box>}
          </Box>

          <Button
            variant="contained"
            size="large"
            type="submit"
            onClick={handleSubmit}
          >
            {localizedStrings['submit'] || 'Submit'}
          </Button>
        </form>
      </Container>
    </Box>
  )
}

export default PageValidationForm
