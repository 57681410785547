import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Calendar from '@react-svgs/calendar.svg'
import ChevronRight from '@react-svgs/chevron_right.svg'
import ChevronLeft from '@react-svgs/chevron_left.svg'
import PlayCircleOutlineIcon from '@react-svgs/play_circle_outline.svg'
import CloseIcon from '@react-svgs/close.svg'
import KeyboardArrowDownIcon from '@react-svgs/keyboard_arrow_down.svg'
import ArrowDropUpIcon from '@react-svgs/arrow_drop_up.svg'
import ArrowDropDownIcon from '@react-svgs/arrow_drop_down.svg'
import SearchIcon from '@react-svgs/search.svg'
import ExpandLess from '@react-svgs/expand_less.svg'
import ExpandMore from '@react-svgs/expand_more.svg'
import PlayArrow from '@react-svgs/play_arrow.svg'
import LargePlayArrow from '@react-svgs/large_play_arrow.svg'
import Add from '@react-svgs/add.svg'
import Subtract from '@react-svgs/subtract.svg'
import FramedPlus from '@react-svgs/framed_plus.svg'
import FramedMinus from '@react-svgs/framed_minus.svg'
import classNames from 'classnames'
import GeoIcon from '@react-svgs/geo.svg'
import ArrowCircleLeft from '@react-svgs/arrow_circle_left.svg'
import VideoPlayCircle from '@react-svgs/video_play_circle.svg'
import Tooltip from '@react-svgs/tooltip_i.svg'
import Checkmark from '@react-svgs/checkmark.svg'
import RightArrow from '@react-svgs/arrow_right.svg'
import ArrowLeft from '@react-svgs/arrow_left.svg'
import LinkedInIcon from '@react-svgs/linkedin.svg'
import TwitterIcon from '@react-svgs/twitter.svg'
import WebIcon from '@react-svgs/web-icon.svg'
import YouTube from '@react-svgs/youtube.svg'

const useStyles = makeStyles(() => ({
  fill: {
    fill: 'currentColor',
  },
}))

const Icon = ({ children, styles }) => {
  const classes = useStyles()

  switch (children) {
    case 'tooltip_i':
    case 'tooltip_info':
      return <Tooltip className={classNames(classes.fill, styles)} />
    case 'chevron_right':
    case 'ChevronRight':
    case 'arrow':
      return <ChevronRight className={classNames(classes.fill, styles)} />
    case 'play_arrow':
    case 'PlayArrow':
      return <PlayArrow className={classNames(classes.fill, styles)} />
    case 'large_play_arrow':
    case 'LargePlayArrow':
      return <LargePlayArrow className={classNames(classes.fill, styles)} />
    case 'PlayCircleOutlineIcon':
    case 'play':
    case 'video':
      return (
        <PlayCircleOutlineIcon className={classNames(classes.fill, styles)} />
      )
    case 'down_arrow':
    case 'expand_more':
    case 'down':
    case 'dropdown':
    case 'KeyboardArrowDownIcon':
      return (
        <KeyboardArrowDownIcon className={classNames(classes.fill, styles)} />
      )
    case 'add':
      return <Add className={classNames(classes.fill, styles)} />
    case 'subtract':
      return <Subtract className={classNames(classes.fill, styles)} />
    case 'framed_plus':
      return <FramedPlus className={classNames(classes.fill, styles)} />
    case 'framed_minus':
      return <FramedMinus className={classNames(classes.fill, styles)} />
    case 'ExpandMore':
      return <ExpandMore className={classNames(classes.fill, styles)} />
    case 'ExpandLess':
      return <ExpandLess className={classNames(classes.fill, styles)} />
    case 'GeoIcon':
      return <GeoIcon className={classNames(classes.fill, styles)} />
    case 'SearchIcon':
      return <SearchIcon className={classNames(classes.fill, styles)} />
    case 'CloseIcon':
      return <CloseIcon className={classNames(classes.fill, styles)} />
    case 'ChevronLeft':
      return <ChevronLeft className={classNames(classes.fill, styles)} />
    case 'ArrowDropUpIcon':
      return <ArrowDropUpIcon className={classNames(classes.fill, styles)} />
    case 'ArrowDropDownIcon':
      return <ArrowDropDownIcon className={classNames(classes.fill, styles)} />
    case 'ArrowCircleLeft':
      return <ArrowCircleLeft className={classNames(classes.fill, styles)} />
    case 'VideoPlayCircle':
      return <VideoPlayCircle className={classNames(classes.fill, styles)} />
    case 'Checkmark':
      return <Checkmark className={classNames(classes.fill, styles)} />
    case 'Calendar':
      return <Calendar className={classNames(classes.fill, styles)} />
    case 'arrow_right':
      return <RightArrow className={classNames(classes.fill, styles)} />
    case 'ArrowLeft':
      return <ArrowLeft className={classNames(classes.fill, styles)} />
    case 'LinkedIn':
      return <LinkedInIcon className={classNames(classes.fill, styles)} />
    case 'Twitter':
      return <TwitterIcon className={classNames(classes.fill, styles)} />
    case 'Web':
      return <WebIcon className={classNames(classes.fill, styles)} />
    case 'YouTube':
      return <YouTube className={classNames(classes.fill, styles)} />
  }

  return null
}

export default Icon
