import Link from '@blok/link'
import PhoneNumber from '@blok/top-nav/phone-number'
import formatLocalizedLink from '@helpers/format-localized-link'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Blockquote, H1, H3, H4, H5, Img, Mark, Text } from '@system'
import { imgMaxWidths } from '@variables/global-variables'
import classNames from 'classnames'
import { marksy } from 'marksy'
import React, { createElement } from 'react'

const useStyles = makeStyles((theme) => ({
  p: ({ variant }) => ({
    color:
      variant === 'pricing' || variant === 'bundles'
        ? theme.palette.primary.main
        : null,
    display: 'inline',
    fontWeight:
      variant === 'bundles' ? theme.typography.fontWeightRegular : 'inherit',
    fontSize:
      variant === 'pricing' ? '20px' : variant === 'bundles' ? '15px' : '20px',
    lineHeight: variant === 'pricing' ? '28px' : '1.5',
    margin: '0.5rem 0',
  }),
  h1: {
    margin: '1.5rem 0 1rem',
  },
  h3: ({ variant }) => ({
    fontWeight: variant === 'pricing' ? theme.typography.fontWeightBold : null,
    margin: '1.5rem 0 1rem',
  }),
  h4: ({ variant }) => ({
    fontWeight: variant === 'pricing' ? theme.typography.fontWeightBold : null,
    margin: '1.5rem 0 1rem',
  }),
  h5: {
    margin: '1.5rem 0 1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  ul: ({ variant }) => {
    if (variant === 'pricing') {
      return {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        listStyleType: 'none',
        margin: '24px 0 0 0',
        padding: '0',
        '& li': {
          backgroundImage:
            'url(https://a.storyblok.com/f/64835/x/3a7bc7a550/circle-check-black.svg)',
          backgroundPosition: '0 center',
          backgroundSize: '18px 18px',
          backgroundRepeat: 'no-repeat',
          marginBottom: '10px',
          paddingLeft: '30px',
        },
      }
    }

    return {
      fontSize: '16px',
      margin: '1rem 0',
      '& li': {
        '&::marker': {
          color: theme.palette.primary.main,
        },
      },
    }
  },
  whiteBullets: {
    margin: '1rem 0',
    '& li': {
      '&::marker': {
        color: theme.palette.common.white,
      },
    },
  },
  ol: {
    margin: '1rem 0',
  },
  blockquote: {
    position: 'relative',
    marginTop: '40px',
    '&:before': {
      position: 'absolute',
      content: 'openQuote',
      fontSize: '4em',
      marginLeft: '-0.6em',
      marginTop: '-0.4em',
    },
    '&:after': {
      position: 'absolute',
      content: 'closeQuote',
      fontSize: '4em',
      marginLeft: '-0.6em',
      marginBottom: '-0.8em',
      bottom: 0,
      right: 0,
    },
    '& p': {
      display: 'inline',
      color: theme.palette.primary.main,
      fontSize: '30px',
    },
  },
  phone: {
    margin: '1.5rem 0',
    '& a': {
      fontSize: '1.2rem',
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  underline: {
    display: 'inline',
    borderBottom: ({ color }) =>
      `1px solid ${color || theme.palette.text.plant}`,
  },
}))

const Markdown = (props) => {
  const compile = (classes) => {
    return marksy({
      createElement,
      elements: {
        h1({ id, children }) {
          return (
            <H1
              className={classNames(classes.h1, props.className)}
              component="h2"
            >
              {children}
            </H1>
          )
        },
        h2({ id, children }) {
          return (
            <H3 className={classNames(classes.h3, props.className)}>
              {children}
            </H3>
          )
        },
        h3({ id, children }) {
          return (
            <H4 className={classNames(classes.h4, props.className)}>
              {children}
            </H4>
          )
        },
        h4({ id, children }) {
          return (
            <H5 className={classNames(classes.h5, props.className)}>
              {children}
            </H5>
          )
        },
        p({ children }) {
          return (
            <Text className={props.className || classes.p}>{children}</Text>
          )
        },
        a({ href, title, target, children }) {
          return (
            <Link
              isDark={props.isDark}
              blok={{
                link: {
                  url: formatLocalizedLink(href),
                  linktype: 'multilink',
                  story: { url: formatLocalizedLink(href) },
                },
                textToHyperlink: children,
              }}
              target={target}
            >
              {children}
            </Link>
          )
        },
        ul({ children }) {
          return (
            <Text
              style={{ lineHeight: 1.4 }}
              className={classes[props.darkBg ? 'whiteBullets' : 'ul']}
              component="ul"
            >
              {children}
            </Text>
          )
        },
        ol({ children }) {
          return (
            <Text
              className={classes.ul}
              style={{ lineHeight: 1.4 }}
              component="ol"
            >
              {children}
            </Text>
          )
        },
        blockquote({ children }) {
          return (
            <Blockquote className={classes.blockquote}>{children}</Blockquote>
          )
        },
        hr() {
          return <Divider />
        },
        img({ src, alt }) {
          return <Img alt={alt} src={src} maxWidth={imgMaxWidths.markdown} />
        },
        br() {
          return <br />
        },
        // table({ children }) {},
        // thead({ children }) {},
        // tbody({ children }) {},
        // tr({ children }) {},
        // th({ children }) {},
        // td({ children }) {},
        // strong({ children }) {},
        // em({ children }) {},
        // del({ children }) {},
        // code({ language, code }) {},
        // codespan({ children }) {},
      },
      components: {
        Mark({ children }) {
          return <Mark>{children}</Mark>
        },
        Phone({ children }) {
          return (
            <div className={classes.phone}>
              <PhoneNumber dashed />
            </div>
          )
        },
        Underline({ children }) {
          return (
            <Text className={classNames(classes.underline, props.className)}>
              {children}
            </Text>
          )
        },
        PageH1({ children, textSize }) {
          switch (textSize) {
            case 'h2': {
              return (
                <H3 className={classes.h3} component={'h1'}>
                  {children}
                </H3>
              )
            }
            case 'h3': {
              return (
                <H4 className={classes.h4} component={'h1'}>
                  {children}
                </H4>
              )
            }
            case 'h4': {
              return (
                <H5 className={classes.h5} component={'h1'}>
                  {children}
                </H5>
              )
            }
            case 'p': {
              return (
                <Text className={classes.p} component={'h1'}>
                  {children}
                </Text>
              )
            }
            default: {
              return (
                <H1 className={classes.h1} component={'h1'}>
                  {children}
                </H1>
              )
            }
          }
        },
      },
    })
  }

  const classes = useStyles(props)
  return props.children ? (
    <>{compile(classes, { breaks: true })(props.children).tree}</>
  ) : null
}

export default Markdown
