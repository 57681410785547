function loadScript(src, id) {
    return new Promise((resolve, reject) => {
      const s = document.createElement("script");
      s.src = src;
      s.id = id;
      s.async = true;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  }
  
  export default loadScript;
 