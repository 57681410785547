import ACC_THEME from '@themes'

const colorIsDark = (color) => {
  let r, g, b

  if (color?.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    const isValid = /^#(?:[0-9A-F]{3}|[0-9A-F]{6})$/i.test(color)
    if (!isValid) return color
    // Account for shortened hex values
    const adjustedColor =
      color.length > 4
        ? color
        : `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`

    // Get rgb values from hex substrings
    r = parseInt(Number(`0x${adjustedColor.slice(1, 3)}`, 10))
    g = parseInt(Number(`0x${adjustedColor.slice(3, 5)}`, 10))
    b = parseInt(Number(`0x${adjustedColor.slice(5, 7)}`, 10))
  }

  // Weighted calculation based on color perception
  return (r * 0.299 + g * 0.587 + b * 0.114) / 256 < 0.5
}

const monochrome = (color) => {
  if (typeof color !== 'undefined' && color === 'transparent') {
    return 'transparent'
  }

  if (Object.values(ACC_THEME.palette.legacy).indexOf(color) < 0) {
    return color
  }

  return ACC_THEME.palette.common[
    !color || colorIsDark(color) ? 'black' : 'white'
  ]
}

const calculateForegroundColor = (bgColor) =>
  ACC_THEME.palette.common[!bgColor || colorIsDark(bgColor) ? 'white' : 'black']

export { colorIsDark, monochrome, calculateForegroundColor }
