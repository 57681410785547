import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    cursor: 'pointer',
  },
}))

// To overlay things correctly, the parent element that contains all the clickable things must be
// position relative
const WistiaVideoOverlay = props => {
  useEffect(() => {
    const json = document.createElement('script')
    json.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`
    json.async = true
    document.body && document.body.appendChild(json)

    const script = document.createElement('script')
    script.src = 'https://fast.wistia.com/assets/external/E-v1.js'
    script.async = true
    document.body && document.body.appendChild(script)
  })

  const classes = useStyles(props)
  const { videoId } = props
  return (
    <div
      className={`wistia_embed wistia_async_${videoId} popover=true videoFoam=true popoverContent=html ${classes.container}`}
      onClick={() => {
        window._wq = window._wq || []
        window._wq.push({
          id: videoId,
          onReady: video => video.play(),
        })
      }}
    />
  )
}

export default WistiaVideoOverlay
