import loadScript from './load-script'

const WISTIA_SCRIPT_ID = 'wistiaScript'
const WISTIA_SCRIPT_URL = '//fast.wistia.com/assets/external/E-v1.js'

export const loadWistiaScript = () => {
  if (
    typeof document !== 'undefined' &&
    !document.getElementById(WISTIA_SCRIPT_ID)
  ) {
    loadScript(WISTIA_SCRIPT_URL, WISTIA_SCRIPT_ID)
  }
}

export default loadWistiaScript
