import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import { H3, H4, H5, Text, TextSm } from '@system'
import { makeStyles } from '@material-ui/styles'
import PageContext from '@context'
import get from 'lodash/get'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    maxWidth: '1056px',
    margin: '0 auto',
    padding: '40px 49.6px 0 49.6px',
  },
  flexBox: {
    display: 'flex',
    paddingBottom: '75px',
    paddingLeft: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  eventBox: {
    marginBottom: '30px',
    maxWidth: '50px',
    height: '165px',
    minWidth: '165px',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    marginRight: '50px',
  },
  eventDate: {
    margin: '0px',
    fontSize: '39px',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  eventMonth: {
    margin: '0px',
    fontSize: '30px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
  },
  eventDetails: {
    flex: 1,
    color: theme.palette.text.tertiary,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main,
    fontFamily: 'Artifakt Element',
    marginBottom: '8px',
    marginTop: '2px',
  },
  address: {
    fontFamily: 'Artifakt Element',
  },
  subTitle: {
    marginTop: '25px',
  },
  link: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    marginTop: 'auto',
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: 'auto',
      fontSize: '15px',
    },
  },
  cardLinks: {
    padding: '20px 0px 0px',
  },
  outerDateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  separaterContainer: {
    margin: '0px 0px',
    fontSize: '39px',
    fontWeight: '500',
  },
  eventMultiMonth: {
    margin: '8px',
    fontSize: '25px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  eventMultiDate: {
    margin: '11px',
    fontSize: '33px',
    fontWeight: '500',
  },
  noEventsResult: {
    color: theme.palette.text.tertiary,
    padding: '0px 15px 50px 15px',
  },
}))

const EventsCard = (props) => {
  const classes = useStyles()
  const allEventStories = props.stories
  const pageContext = useContext(PageContext)
  const unitedStatesOfAmerica = 'United States of America'
  const canada = 'Canada'

  const sortedEventStories =
    allEventStories &&
    typeof allEventStories === 'object' &&
    allEventStories.sort((firstEvent, secondEvent) =>
      firstEvent.startDate > secondEvent.startDate
        ? 1
        : firstEvent.startDate < secondEvent.startDate
        ? -1
        : 0
    )

  const getEventsDateAndTime = (startDate, endDate) => {
    const yearOfStartDate = getFormattedDateTime(startDate, 'YYYY')
    const yearOfEndDate = getFormattedDateTime(endDate, 'YYYY')
    const monthOfStartDate = getFormattedDateTime(startDate, 'MMMM')
    const monthOfEndDate = getFormattedDateTime(endDate, 'MMMM')

    const FormattedDate = (props) => (
      <Box>
        <H4 className={classes.eventMultiMonth}>
          {getFormattedDateTime(props.date, 'MMM')}
        </H4>
        <H3 className={classes.eventMultiDate}>
          {getFormattedDateTime(props.date, 'DD')}
        </H3>
      </Box>
    )

    const eventFormattedDate = (
      <Box className={classes.outerDateContainer}>
        <FormattedDate date={startDate} />
        <Box className={classes.separaterContainer}>{' - '}</Box>
        <FormattedDate date={endDate} />{' '}
      </Box>
    )

    if (dayjs(startDate).isSame(dayjs(endDate))) {
      return (
        <>
          <H4 className={classes.eventMonth}>
            {getFormattedDateTime(startDate, 'MMM')}
          </H4>
          <H3 className={classes.eventDate}>
            {getFormattedDateTime(startDate, 'DD')}
          </H3>
        </>
      )
    } else if (yearOfStartDate === yearOfEndDate) {
      return monthOfStartDate === monthOfEndDate ? (
        <>
          <H4 className={classes.eventMonth}>
            {getFormattedDateTime(startDate, 'MMM')}
          </H4>
          <H3 className={classes.eventDate}>
            {getFormattedDateTime(startDate, 'DD')}
            {' - '}
            {getFormattedDateTime(endDate, 'DD')}
          </H3>
        </>
      ) : (
        eventFormattedDate
      )
    } else eventFormattedDate
  }

  return (
    <Box className={classes.outerContainer}>
      {!sortedEventStories ? (
        <H4 className={classes.noEventsResult}>{allEventStories}</H4>
      ) : (
        sortedEventStories.map((event) => {
          const address =
            event.country === unitedStatesOfAmerica || event.country === canada
              ? !!event.streetAddress && `${event.city}, ${event.state}`
              : !!event.streetAddress && `${event.city}, ${event.country}`

          const link = get(
            !!pageContext.events &&
              pageContext.events.filter(
                (eachEvent) => eachEvent.content.title === event.title
              ),
            '[0].slug'
          )
          return (
            <Box className={classes.flexBox} key={event._uid}>
              <Box className={classes.eventBox}>
                {event.startDate &&
                  event.endDate &&
                  getEventsDateAndTime(event.startDate, event.endDate)}
              </Box>
              <Box className={classes.eventDetails}>
                {event.title && (
                  <H4 className={classes.title}>{event.title}</H4>
                )}
                {address && <H5 className={classes.address}>{address}</H5>}
                {event.subtitle && (
                  <Text className={classes.subTitle}>{event.subtitle}</Text>
                )}
                <Box className={classes.cardLinks}>
                  {(event.title || event.description) && (
                    <TextSm component="span" className={classes.link}>
                      <a href={link}>{'More details'}</a>
                    </TextSm>
                  )}
                </Box>
              </Box>
            </Box>
          )
        })
      )}
    </Box>
  )
}

export default EventsCard
