import React from 'react'
import { Typography } from '@material-ui/core'

const H2 = ({ children, ...otherProps }) => {
  return (
    <Typography component="h2" variant="h2" color="primary" {...otherProps}>
      {children}
    </Typography>
  )
}

export default H2
