import CryptoJS from 'crypto-js'
import forOwn from 'lodash/forOwn'

const defaultFormKeys = [
  'Company',
  'Email',
  'FirstName',
  'LastName',
  'Phone',
  'Title',
]

const makeFormInputKeys = (formObj, cb) => {
  const names = []
  forOwn(formObj.getFormElem()['0'], (val) => {
    if (typeof val === 'object' && !!val.name) {
      names.push(val.name)
    }
  })
  !!cb && cb(names)
  return names
}

const formCookieKey = 'adsk_mkto_default'

const getEncryptedCookie = (savedValues) => {
  const bytes = CryptoJS.AES.decrypt(savedValues, formCookieKey)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

const saveEncryptedValuesToCookieStore = (values, savedValues, formKeys) => {
  // make sure old values aren't erased that happen to not be in this form
  const valuesObj = savedValues ? getEncryptedCookie(savedValues) : {}
  const keys = formKeys ? formKeys : defaultFormKeys
  keys.forEach((item) => {
    if (values[item]) {
      valuesObj[item] = values[item]
    }
  })

  const cryptoCookies = CryptoJS.AES.encrypt(
    JSON.stringify(valuesObj),
    formCookieKey
  ).toString()

  setCookie(formCookieKey, cryptoCookies, 365, true)
}

const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie =
    typeof document != 'undefined' ? decodeURIComponent(document.cookie) : ''
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

const setCookie = (cname, cvalue, exdays, siteWide) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)

  if (typeof document != 'undefined') {
    if (siteWide) {
      document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};SameSite=secure;path=/`
    } else {
      document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};SameSite=Strict;`
    }
  }
}

export {
  setCookie,
  getCookie,
  getEncryptedCookie,
  formCookieKey,
  defaultFormKeys,
  saveEncryptedValuesToCookieStore,
  makeFormInputKeys,
}
