import get from 'lodash/get'

const getSortedStories = (
  filteredNarrowsArray,
  sortBy,
  stories,
  isEventsModule
) => {
  const alphabetical = 'alphabetical'
  const chronological = 'chronological'
  const reverseChronological = 'reverse-chronological'

  const compare = (firstObj, secondObj) => {
    firstObj =
      stories &&
      get(
        stories.filter(
          (filterResource) => filterResource.content.title === firstObj.title
        ),
        '[0]'
      )
    secondObj =
      stories &&
      get(
        stories.filter(
          (filterResource) => filterResource.content.title === secondObj.title
        ),
        '[0]'
      )
    if (firstObj && secondObj) {
      let comparison = 0
      if (sortBy === 'none') {
        comparison = 0
      } else {
        const nameA =
          sortBy === alphabetical
            ? firstObj.name
            : sortBy === chronological || sortBy === reverseChronological
            ? isEventsModule
              ? firstObj.content.startDate
              : firstObj.first_published_at || firstObj.created_at
            : null
        const nameB =
          sortBy === alphabetical
            ? secondObj.name
            : sortBy === chronological || sortBy === reverseChronological
            ? isEventsModule
              ? secondObj.content.startDate
              : secondObj.first_published_at || secondObj.created_at
            : null
        if (nameA > nameB) comparison = 1
        else if (nameA < nameB) comparison = -1
      }
      return comparison
    }
  }

  return filteredNarrowsArray && filteredNarrowsArray.sort(compare)
}

export default getSortedStories
