import React from 'react'
import { Typography } from '@material-ui/core'

const H5 = ({ children, ...otherProps }) => {
  return (
    <Typography component="h5" variant="h5" color="primary" {...otherProps}>
      {children}
    </Typography>
  )
}

export default H5
