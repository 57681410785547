import addPrecedingAndRemoveTrailingSlashes from '@helpers/add-preceding-and-remove-trailing-slashes'
import formatLocalizedLink from '@helpers/format-localized-link'

// Takes a Storyblok multilink field's data and formats it to give you the
// relevant url.  We use the resolve_links=url config option.  You can see
// more at https://www.storyblok.com/cl/url-resolving

const multilinkToUrl = ({ url, linktype, story }, isInEditor = false) => {
  const isStory = linktype === 'story'
  const sbUrl =
    isStory &&
    story &&
    story.url &&
    addPrecedingAndRemoveTrailingSlashes(story.url)

  return isStory && !!sbUrl
    ? isInEditor
      ? `/editor/?path=${sbUrl}`
      : formatLocalizedLink(sbUrl)
    : formatLocalizedLink(url) || ''
}

export default multilinkToUrl
