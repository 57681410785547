import { Mark } from '@system'
import React from 'react'

const highlightText = (text, selectedText, isMarkdown = false) => {
  if (text?.includes(selectedText)) {
    if (isMarkdown) {
      return text.replace(
        selectedText,
        `&nbsp;\n~~~marksy\nh(Mark, {}, "${selectedText}")\n~~~\n`
      )
    }

    return text
      .split(selectedText)
      .flatMap((item) => [item, <Mark>{selectedText}</Mark>])
      .slice(0, -1)
  }

  return text
}

export default highlightText
