import React, { useRef } from 'react'
import {
  Box,
  ListItem,
  Drawer,
  ListItemText,
  Button,
  useMediaQuery,
  Collapse,
  Divider,
  List,
} from '@material-ui/core'
import ACC_THEME from '@themes'
import renderBloks from '@renderBloks'
import ContentContainer from '@system/content-container'
import { Img } from '@system'
import classNames from 'classnames'
import PhoneNumber from './phone-number'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import multilinkToUrl from '@helpers/multilink-to-url'
import GeoDropdown from './geo-dropdown'
import get from 'lodash/get'
import Icon from '@system/icon'
import KeyboardArrowRightIcon from '@react-svgs/keyboard_arrow_right.svg'
import ExpandMore from '@react-svgs/expand_more.svg'
import { LogoLink } from './shared'

const LoginMenuMobile = ({
  login,
  anchorRefMobileMenu,
  classes,
  handleClick,
  handleListKeyDown,
  openmobilemenu,
  isInEditor,
}) => {
  const loginButton = get(login, '[0]')
  return (
    <List component="nav" className={classes.fullWidthMobileNavItem}>
      <ListItem
        ref={anchorRefMobileMenu}
        aria-controls={openmobilemenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={classes.drawerHeadline}
        onClick={handleClick}
        button
      >
        <ContentContainer style={{ alignItems: 'center', display: 'flex' }}>
          <ListItemText
            primary={get(loginButton.mainButton, '[0]').textToHyperlink}
          />
          {openmobilemenu ? (
            <Icon style={classes.iconLoginCustom}>ExpandMore</Icon>
          ) : (
            <Icon style={classes.iconLoginCustom}>ChevronRight</Icon>
          )}
        </ContentContainer>
      </ListItem>
      <Collapse in={openmobilemenu} timeout="auto" unmountOnExit>
        <ContentContainer style={{ paddingBottom: '32px' }}>
          <MenuList
            className={classes.menuLoginCustom}
            autoFocusItem={openmobilemenu}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
          >
            {loginButton.menuItems &&
              loginButton.menuItems.map((navItem) => {
                const href =
                  navItem.link && multilinkToUrl(navItem.link, isInEditor)
                return (
                  <MenuItem key={navItem._uid} className={classes.loginItem}>
                    <Button
                      href={href}
                      key={navItem._uid}
                      height="100%"
                      width="100%"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                      }}
                    >
                      {navItem.icon && (
                        <Img
                          src={get(navItem.icon, '[0]').image}
                          alt={get(navItem.icon, '[0]').title}
                          style={{
                            height: '20px',
                            marginRight: '14px',
                            objectFit: 'contain',
                            objectPosition: 'left',
                            width: '20px',
                          }}
                          width="20px"
                        />
                      )}

                      {navItem.textToHyperlink}
                    </Button>
                    {!!navItem.subLink && (
                      <Box className={classes.mobileSublink}>
                        {renderBloks(navItem.subLink)}
                      </Box>
                    )}
                  </MenuItem>
                )
              })}
          </MenuList>
        </ContentContainer>
      </Collapse>
      <Divider
        style={{
          backgroundColor: '#FFF',
          width: '100vw',
        }}
      />
    </List>
  )
}

const MobileMenu = (props) => {
  const {
    anchorRefMobileMenu,
    classes,
    geoOpen,
    handleClick,
    handleListKeyDown,
    isCustomNavLogin,
    isDefaultLogin,
    isInEditor,
    login,
    loginButtonRef,
    logoImage,
    mobileDrawerStatus,
    navItems,
    openmobilemenu,
    phoneNumber,
    setGeoOpen,
    toggleDrawer,
  } = props
  const anchorRef = useRef(null)
  const handleGeoMenuClick = () => {
    setGeoOpen(!geoOpen)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      open={mobileDrawerStatus}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        id="top-nav"
        style={{ backgroundColor: '#000002', marginBottom: '5px' }}
      >
        <ContentContainer
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            height: '60px',
          }}
        >
          <LogoLink
            handHeldDevice={true}
            invertedNav={props.invertedNav}
            logoImage={logoImage}
          />
          <div
            onClick={toggleDrawer}
            style={{
              height: '30px',
              width: '30px',
            }}
          >
            <img
              src={'https://a.storyblok.com/f/64835/x/29e2b2a6b2/close.svg'}
              alt="Close Icon"
              style={{
                display: 'block',
                transform: 'scale(1.10)',
                width: '100%',
              }}
            />
          </div>
        </ContentContainer>
      </div>
      {renderBloks(navItems)}
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        color="#FFFFFF"
      >
        {isCustomNavLogin ? (
          <LoginMenuMobile
            anchorRefMobileMenu={anchorRefMobileMenu}
            classes={classes}
            handleClick={handleClick}
            isInEditor={isInEditor}
            login={props.customNavLogin}
            loginButtonRef={loginButtonRef}
            onKeyDown={handleListKeyDown}
            openmobilemenu={openmobilemenu}
          />
        ) : isDefaultLogin ? (
          <LoginMenuMobile
            anchorRefMobileMenu={anchorRefMobileMenu}
            classes={classes}
            handleClick={handleClick}
            isInEditor={isInEditor}
            login={login}
            loginButtonRef={loginButtonRef}
            onKeyDown={handleListKeyDown}
            openmobilemenu={openmobilemenu}
          />
        ) : (
          <List component="nav">
            <ListItem className={classes.drawerHeadline} button>
              <ContentContainer
                style={{ alignItems: 'center', display: 'flex' }}
              >
                <ListItemText
                  style={{ marginLeft: '32px' }}
                  primary={get(login, '[0]').textToHyperlink}
                />
                <Divider
                  style={{
                    backgroundColor: '#FFF',
                  }}
                />
              </ContentContainer>
            </ListItem>
          </List>
        )}
        {!props.isLandingPage && (
          <List component="nav" className={classes.fullWidthMobileNavItem}>
            <ListItem
              ref={anchorRef}
              aria-controls={geoOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classNames(classes.drawerHeadline, classes.geoDrawer)}
              onClick={handleGeoMenuClick}
              button
            >
              <ContentContainer
                style={{ alignItems: 'center', display: 'flex' }}
              >
                <ListItemText primary={'Region'} />
                {geoOpen ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                <Divider
                  style={{
                    backgroundColor: '#FFF',
                  }}
                />
              </ContentContainer>
            </ListItem>
            <Collapse in={geoOpen} timeout="auto" unmountOnExit>
              <ContentContainer style={{ paddingBottom: '32px' }}>
                <GeoDropdown
                  inverted={props.invertedNav}
                  alternates={props.alternates}
                  geoOpen={geoOpen}
                  handleClose={() => setGeoOpen(false)}
                  handleGeoMenuClick={handleGeoMenuClick}
                  style={{ height: '23px', width: '23px' }}
                />
              </ContentContainer>
            </Collapse>
            <Divider
              style={{
                backgroundColor: '#FFF',
                width: '100vw',
              }}
            />
          </List>
        )}
        <List component="nav" className={classes.fullWidthMobileNavItem}>
          <ListItem className={classes.drawerHeadline} button>
            <ContentContainer>
              <PhoneNumber
                number={props.topNavPhone}
                defaultNumber={phoneNumber}
                isDark
                showContactUs
                mobile
              />
            </ContentContainer>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}

export default MobileMenu
