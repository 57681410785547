import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: 0,
    borderTop: `${props.lineHeight}px solid ${props.color.color}`,
    width: `${props.widthProportion || 100}%`,
    margin: `${props.margin}` || '0px',
    [theme.breakpoints.down('xs')]: {
      display: props.hideForMobile ? 'none' : 'block',
    },
  }),
  // you cannot conditionally render a gradient border, so we must use a whole new object
  gradient: (props) => ({
    height: 0,
    border: '2px solid',
    borderImageSlice: 1,
    borderImageSource: `linear-gradient(90deg, rgba(32, 152, 212, 0) 0%, rgba(32, 152, 212, 0.4) 28.12%, rgba(32, 152, 212, 0.4) 72.92%, rgba(32, 152, 212, 0) 100%)`,
    width: `${props.widthProportion || 100}%`,
    margin: `${props.margin}` || '0px',
    [theme.breakpoints.down('xs')]: {
      display: props.hideForMobile ? 'none' : 'initial',
    },
  }),
}))

const HR = (props) => {
  const classes = useStyles(props)
  return (
    <Box
      className={classes[props.variant === 'gradient' ? 'gradient' : 'root']}
    />
  )
}

export default HR
