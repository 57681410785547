import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Box from '@material-ui/core/Box'
import H5 from '@system/h5'
import H6 from '@system/h6'
import Img from '@system/img'
import ACC_THEME from '@themes'
import classNames from 'classnames'
import font from '@helpers/font'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& #icon-wrapper:first-child #phase:before, & #icon-wrapper:last-child #phase:after':
      {
        background: 'none',
      },
  },
  iconWrapper: {
    flex: 1,
  },
  phase: {
    marginTop: '40px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before, &:after': {
      content: "''",
      width: '50%',
      height: '4px',
      top: 0,
      position: 'absolute',
      background: theme.palette.linkWater,
      [theme.breakpoints.down('xs')]: {
        marginTop: '-4px',
      },
    },

    '&:before': {
      left: 0,
    },

    '&:after': {
      right: 0,
    },
  },
  activeIcon: {
    border: `4px solid ${theme.palette.secondary.main} !important`,
  },
  icon: {
    border: `4px solid ${theme.palette.linkWater}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    position: 'absolute',
    top: '-28px',
    zIndex: 10,
    left: 0,
    right: 0,
    margin: '0px auto',
    height: '60px',
    width: '60px',

    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      width: '50px',
      height: '50px',
      top: '-24px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50px',
      height: '50px',
    },

    '& .gatsby-image-wrapper': {
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
      },

      [theme.breakpoints.up('sm')]: {
        width: '40px',
        height: '40px',
      },

      '& > div': {
        display: 'none',
      },
      '& img': {
        position: 'static !important',
      },
    },
  },
  activeName: {
    fontWeight: `${theme.typography.fontWeightMedium} !important`,
    color: `${theme.palette.text.activePhase} !important`,
  },
  phaseTitle: {
    textTransform: 'uppercase',
    color: theme.palette.text.phase,
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      ...makeResponsiveFontSizes(16, 11),
    },
    display: 'block',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
  },
  fadedImage: {
    '& img': {
      opacity: '0.4',
      filter: 'grayscale(100%)',
    },
  },
  image: {
    '& img': {
      width: '80% !important',
      height: '65px',
      objectFit: 'contain',
    },
  },
}))

const Phases = (props) => {
  const classes = useStyles(props)
  const phases = [
    {
      name: 'Design',
      imgPath: '//a.storyblok.com/f/64835/x/296e024325/design-icon.svg',
    },
    {
      name: 'Plan',
      imgPath: '//a.storyblok.com/f/64835/x/6ee8130178/plan-icon.svg',
    },
    {
      name: 'Build',
      imgPath: '//a.storyblok.com/f/64835/x/bcc25834e8/build-icon.svg',
    },
    {
      name: 'Operate',
      imgPath: '//a.storyblok.com/f/64835/x/b4cde725ec/operate-icon.svg',
    },
  ]

  return (
    <Box className={classes.mainContainer}>
      {phases.map((eachPhase) => (
        <Box className={classes.iconWrapper} id="icon-wrapper">
          <H5 className={classes.phaseTitle}>
            <Box id="phase" className={classes.phase}>
              <H6
                className={classNames(classes.phaseTitle, {
                  [classes.activeName]:
                    !!props.phase &&
                    props.phase
                      .map((phase) => phase)
                      .indexOf(eachPhase.name) !== -1,
                })}
              >
                {eachPhase.name}
              </H6>
              <Box
                className={classNames(classes.icon, {
                  [classes.activeIcon]:
                    !!props.phase &&
                    props.phase
                      .map((phase) => phase)
                      .indexOf(eachPhase.name) !== -1,
                })}
              >
                <Box
                  className={classNames(classes.iconBox, classes.image, {
                    [classes.fadedImage]:
                      !!props.phase &&
                      props.phase
                        .map((phase) => phase)
                        .indexOf(eachPhase.name) === -1,
                  })}
                >
                  {<Img src={eachPhase.imgPath} />}
                </Box>
              </Box>
            </Box>
          </H5>
        </Box>
      ))}
    </Box>
  )
}

export default Phases
