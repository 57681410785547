import * as dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const getFormattedDateTime = (date, format, utc = true) => {
  // If the format requested includes a timezone (z),
  // try to guess the timezone using the dayjs plugins timezone and advanced.
  if (date && format?.includes(' z')) {
    const timezone = dayjs.tz.guess()
    return dayjs(`${date}Z`.replace(' ', 'T')).tz(timezone).format(format)
  }

  return dayjs(date).utc(utc).local().format(format)
}

export default getFormattedDateTime
