import React from 'react'
import Bloks from '@bloks'

const RenderBloks = (bloks, props) => {
  return (
    !!bloks &&
    !!bloks.length &&
    bloks.map((blok) => {
      if (blok.component === 'resourceDetail') {
        console.log('This bloks -> ', blok)
      }
      return React.createElement(Bloks(blok.component), {
        ...props,
        key: blok._uid,
        blok: blok,
      })
    })
  )
}

export default RenderBloks
