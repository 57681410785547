const addPrecedingSlash = (str) => {
  if (!str) {
    console.warn(
      `WARNING: String in addPrecedingSlash was null.  Check for bad data.`
    )
    return '/'
  }

  const trimmed = str.trim()

  // Does check for absolute urls
  return trimmed[0] === '/' ||
    trimmed.indexOf('http:') === 0 ||
    trimmed.indexOf('https:') === 0 ||
    trimmed.indexOf('mailto:') === 0 ||
    trimmed.indexOf('#') === 0 ||
    trimmed.indexOf('tel:') === 0
    ? trimmed
    : '/' + trimmed
}

export default addPrecedingSlash
