import React from 'react'
import { Typography } from '@material-ui/core'
import { H3 } from '@system'

const Blockquote = ({ children, ...otherProps }) => {
  return (
    <H3 component="blockquote" {...otherProps}>
      {children}
    </H3>
  )
}

export default Blockquote
