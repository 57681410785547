import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Radio,
  RadioGroup as MUIRadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import { InputField } from '@system'
import useStringTranslation from '@hooks/use-string-translation'

const useStyles = makeStyles((theme) => ({
  radioGroupRoot: {
    '& label': {
      color: `${theme.palette.common.black} !important`,
      fontSize: '16px !important',
      transform: 'none !important',
    },
    '& svg': {
      color: `${theme.palette.common.black} !important`,
    },
    '& span': {
      fontSize: '16px !important',
    },
    '& span svg': {
      fontSize: '22px !important',
    },
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  otherInputContainer: {
    marginTop: '-5px',
    marginLeft: '30px',
  },
  falseLabel: {
    '&::before': {
      fontSize: '12px',
      content: ({ localizedOptionText }) => `'(${localizedOptionText})'`,
      color: theme.palette.text.tertiary,
    },
  },
  otherInput: {
    width: '100%',
    marginTop: '3px !important',
    '& input': {
      height: '44px',
      padding: '0px 14px',
    },
    '& fieldset': {
      borderRadius: '0 !important',
    },
  },
}))

const RadioGroup = (props) => {
  const {
    fieldName,
    fieldId,
    options,
    customOtherFieldName,
    hasOtherField,
    form,
    placeholder,
  } = props
  const localizedOptionText = useStringTranslation('optional')
  const classes = useStyles({ localizedOptionText })

  const handleChange = (event) => {
    form.setFieldValue(fieldId, event.target.value)
  }

  const formatOtherLabel = (label) => label.replace(/:$|$/, ':')

  return (
    <Box className={classes.radioGroupRoot}>
      <MUIRadioGroup
        aria-label={fieldName}
        name="radioSelect"
        onChange={handleChange}
      >
        {options.map((option) => (
          <Box key={`radio_${option}`}>
            <FormControlLabel
              className={classes.radioLabel}
              value={option}
              control={<Radio className={classes.radioRoot} />}
              label={
                option.toLowerCase() === 'other'
                  ? formatOtherLabel(customOtherFieldName || option)
                  : option
              }
            />
            {option.toLowerCase() === 'other' && hasOtherField && (
              <Box className={classes.otherInputContainer}>
                <Box className={classes.falseLabel} />
                <InputField
                  className={classes.otherInput}
                  required={false}
                  placeholder={placeholder}
                  type="text"
                  name="Other"
                  ariaLabel="other"
                  margin="none"
                  onChange={handleChange}
                />
              </Box>
            )}
          </Box>
        ))}
      </MUIRadioGroup>
    </Box>
  )
}

export default RadioGroup
