import { makeStyles } from '@material-ui/styles'
import { Icon, Img, Link } from '@system'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  profileCardRoot: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    boxShadow: theme.boxShadow.profileCard,
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '4px',
  },
  profileCardImage: {
    borderRadius: '4px 4px 0px 0px',
    height: '168px',
  },
  profileCardDetails: {
    flex: '1',
    padding: '0 8px',
  },
  profileCardDetailsName: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightExtraBold,
    lineHeight: '1.2',
    margin: '0 0 2px',
  },
  profileCardDetailsList: {
    fontSize: '13px',
    listStyleType: 'none',
    margin: '0',
    padding: '0',
  },
  profileCardLink: {
    alignItems: 'center',
    color: theme.palette.common.black,
    display: 'flex',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    gap: '8px',
    margin: '0 8px 12px',
    textDecoration: 'none',
    '& > svg': {
      height: '24px',
      transform: 'rotate(180deg)',
      width: '24px',
      '& path': {
        fill: theme.palette.common.black,
      },
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const ProfileCard = ({
  company,
  firstName,
  fullSlug,
  headshot,
  jobTitle,
  lastName,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.profileCardRoot}>
      {headshot?.length > 0 && (
        <Img
          className={classes.profileCardImage}
          doNotScale={true}
          maxWidth={200}
          src={headshot[0]?.image}
        />
      )}
      <div className={classes.profileCardDetails}>
        {firstName && lastName && (
          <h4 className={classes.profileCardDetailsName}>
            {firstName} {lastName}
          </h4>
        )}
        {(company || jobTitle) && (
          <ul className={classes.profileCardDetailsList}>
            {jobTitle && <li>{jobTitle}</li>}
            {company && <li>{company}</li>}
          </ul>
        )}
      </div>
      {fullSlug && (
        <Link
          link={{
            linktype: 'story',
            story: { url: fullSlug },
          }}
          linkStyle={classes.profileCardLink}
        >
          <Icon>ArrowCircleLeft</Icon>
          Learn more
        </Link>
      )}
    </div>
  )
}

export default ProfileCard
