import { useState, useEffect } from 'react'
import 'isomorphic-fetch'

const useLocaleByGeoIP = () => {
  const [locale, setLocale] = useState('')
  useEffect(() => {
    fetch('/geo/default.json')
      .then((res) => res.json())
      .then((data) => {
        setLocale(data.acc_geo)
      })
      .catch((err) => console.warn(err, 'error'))
  })
  return locale
}

export default useLocaleByGeoIP
