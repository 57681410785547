import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import renderBlok from '@renderBlok'
import SocialLinks from './SocialLinks'
import useStringTranslation from '@hooks/use-string-translation'

const useStyles = makeStyles((theme) => ({
  bottomLinks: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.disabled,
    whiteSpace: 'pre',
    display: 'inline-block',
    marginBottom: '2px',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.background.paper,
    },
  },
  bottomRow: {
    marginTop: '10px',
  },
}))

const BottomLinks = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'))
  const year = new Date().getFullYear()
  const translatedLinkText = useStringTranslation('Cookie preferences')

  return (
    <Grid
      className={classes.bottomRow}
      container
      item
      direction={!mdDown || mdOnly ? 'row' : 'column-reverse'}
      justifyContent="space-between"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item md={7} lg={8}>
        {!!props.bottomLinkItems.bottomLinks &&
          props.bottomLinkItems.bottomLinks.map((link, i) => (
            <Box key={link._uid} className={classes.bottomLinks}>
              {i !== 0 && ` | `}
              {link.textToHyperlink ===
              'Do not sell or share my personal information' ? (
                <Box className={classes.bottomLinks}>
                  <a
                    className={classes.bottomLinks}
                    data-wat-linkname="manage-ccpa-settings-footer-link"
                    href={link.link.url}
                  >
                    Do not sell or share my personal information
                  </a>
                </Box>
              ) : link.isCookiePreferences ? (
                <Box className={classes.bottomLinks}>
                  <a
                    className={classes.bottomLinks}
                    data-opt-in-preferences
                    onClick={(e) => e.preventDefault()}
                  >
                    {translatedLinkText}
                  </a>
                </Box>
              ) : i === props.bottomLinkItems.bottomLinks.length - 1 ? (
                `© ${year} Autodesk, Inc. ${link.content}`
              ) : (
                renderBlok(link)
              )}
            </Box>
          ))}
      </Grid>
      <SocialLinks socialLinkItems={props.bottomLinkItems} />
    </Grid>
  )
}

export default BottomLinks
