import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { getCountdownTime } from '@helpers'

const useStyles = makeStyles((theme) => ({
  countdownTimer: {
    backgroundColor: theme.palette.background.gold,
    height: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.black,
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const EventTimer = ({ startDate }) => {
  const [countdown, setCountdown] = useState(getCountdownTime(startDate))
  const [countdownDisplay, setCountdownDisplay] = useState(`--:--:--`)
  const classes = useStyles()

  const timerFunction = (init) => {
    const newCountdown = init ? countdown : countdown.subtract(1, 'seconds')
    const days = Math.floor(newCountdown.asDays())
    const hours = Math.floor(newCountdown.asHours()) - days * 24
    const minutes =
      Math.floor(newCountdown.asMinutes()) - days * 1440 - hours * 60
    const seconds =
      Math.floor(newCountdown.asSeconds()) -
      days * 86400 -
      hours * 3600 -
      minutes * 60

    // prettier-ignore
    const displayString = `${days ? `${days}d:` : ''}${hours ? `${hours}h:` : ''}${minutes ? `${minutes}m:` : '0m:'}${seconds ? `${seconds}s` : '0s'} left`

    setCountdown(newCountdown)
    if (seconds >= 0) {
      setCountdownDisplay(displayString)
    }
  }

  useEffect(() => {
    const timer = setTimeout(timerFunction, 1000)
    return () => clearTimeout(timer)
  }, [countdown])

  useEffect(() => {
    timerFunction(true)
  }, [])

  return (
    <Box>
      {countdown.asSeconds() >= 0 && (
        <Box className={classes.countdownTimer}>{countdownDisplay}</Box>
      )}
    </Box>
  )
}

export default EventTimer
