import getCurrentDateAndTimeInUTC from './get-current-date-and-time-in-UTC'
import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const getCountdownTime = (targetDate) => {
  const currentDate = getCurrentDateAndTimeInUTC()
  const now = dayjs(currentDate)
  const target = dayjs(targetDate)
  return dayjs.duration(target.diff(now))
}
export default getCountdownTime
