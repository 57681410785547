// prettier-ignore
const regions = {
  apac: ['af','as','au','bd','bt','io','bn','kh','cn','cx','cc','ck','fm','fj','pf','gu','in','id','ki','la','mo','my','mv','mh','mn','mm','nr','np','nc','nz','nu','nf','kp','mp','pk','pw','pg','ph','pn','ru','as','sb','kr','lk','tw','th','tl','tk','to','tv','vu','vn','wf',],
  emea: ['al','dz','ad','ao','at','bh','by','be','bj','ba','bw','bg','bf','bi','cm','cv','cf','td','km','hr','cy','cz','cd','dk','dj','eg','gq','er','ee','et','fo','fi','fr','ga','gm','ge','gh','gi','gr','gg','gn','gw','hu','is','ir','iq','im','il','ci','je','jo','ke','kw','lv','lb','ls','lr','ly','li','lt','lu','mk','mg','mw','ml','mt','mr','mu','md','mc','me','ma','mz','na','ne','ng','no','om','ps','pt','qa','ro','rw','sm','st','sa','sn','rs','sk','si','so','za','es','sd','sz','ch','sy','tz','tg','tn','tr','ug','ua','ae','gb','va','eh','ye','zm','zw',],
}

const getRegion = (locale) => {
  return regions.emea.indexOf(locale) > -1
    ? 'emea'
    : regions.apac.indexOf(locale) > -1
    ? 'apac'
    : 'us'
}

export default getRegion
