import React from 'react'
import SbEditable from 'storyblok-react'
import { ThemeProvider } from '@material-ui/styles'
import { Box, Container, CssBaseline } from '@material-ui/core'
import ACC_THEME from '@themes'
import BOTTOM_NAV_THEME from '@themes/bottom-nav'
import Menu from './bottom-nav/Menu'
import PageContainer from '../system/page-container'

const BottomNav = (props) => {
  return (
    <SbEditable content={props.blok}>
      <PageContainer
        id="bottom"
        backgroundColor={BOTTOM_NAV_THEME.palette.background.default}
      >
        <ThemeProvider theme={ACC_THEME}>
          <ThemeProvider
            theme={(OUTER_THEME) => ({ ...OUTER_THEME, ...BOTTOM_NAV_THEME })}
          >
            <CssBaseline />
            <Box pt={8} pb={4} bgcolor="default">
              <Container fixed>
                <Menu menuItems={props.blok} />
              </Container>
            </Box>
          </ThemeProvider>
        </ThemeProvider>
      </PageContainer>
    </SbEditable>
  )
}

export default BottomNav
