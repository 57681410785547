import React from 'react'
import { Typography } from '@material-ui/core'

const TextSm = ({ children, ...otherProps }) => {
  return (
    <Typography variant="body2" {...otherProps}>
      {children}
    </Typography>
  )
}

export default TextSm
