// prettier-ignore
const bloksWithH1s = {
  cardModule: [ {fieldName: 'title'} ],
  customerLogosModule: [ {fieldName: 'headline'} ],
  feature: [ {fieldName: 'title'} ],
  headlineDescriptionWithCta: [ {fieldName: 'headline'} ],
  headlineDescriptionWithImagesAndCta: [ {fieldName: 'headline'} ],
  heroFrame: [ {fieldName: 'eyebrowLabel', checkbox: 'alertTextIsH1'}, {fieldName: 'title'} ],
  longFormTextModule: [ {fieldName: 'title'} ],
  productLogo: [ {fieldName: 'productImage', checkbox: 'isH1ForPage', isManualSelect: true} ],
  marketoFormModule: [ {fieldName: 'title', checkbox: 'titleIsH1ForPage', isManualSelect: true} ],
  marketoFormEmbedModule: [ {fieldName: 'title', checkbox: 'titleIsH1ForPage', isManualSelect: true} ],
  titleSubtitleDescriptionModule: [ {fieldName: 'iconText', checkbox: 'iconTextIsH1'}, {fieldName: 'title'}, {fieldName: 'subtitle'} ],
  visionModule: [ { fieldName: 'title' } ],
}

const componentsWithNestedContent = {
  box: ['content'],
  twoColumnLayoutModule: ['leftColumn', 'rightColumn'],
}

export default function findAndAssignH1(initialArray, pageComponentHasH1) {
  if (pageComponentHasH1) return initialArray
  // TODO: Handle A-B tests concurrent with Mutiny update
  let h1Prime = null

  const checkH1Priority = (component) => {
    const name = component.component
    const h1Options = bloksWithH1s[name]
    const hasValidH1Field = h1Options?.some(
      (option) =>
        // One of the fields is present and has data
        // AND there is not already a component assigned to h1Prime
        (!!component[option.fieldName] &&
          (!option.checkbox || !!component[option.checkbox]) &&
          !h1Prime) ||
        // OR
        // Component is manually selected as h1ForPage via SB boolean(checked)
        // AND field is present and has data
        // AND h1Prime does not already have a manually selected SB boolean
        (option.isManualSelect &&
          !!component[option.checkbox] &&
          (!h1Prime || !h1Prime[option.checkbox]))
    )
    return hasValidH1Field
  }

  const parseContentForH1 = (contentArray) => {
    if (!contentArray || !contentArray?.length) return

    contentArray.forEach((component) => {
      const componentName = component?.component
      // prettier-ignore
      const blokCouldRenderH1 = bloksWithH1s[componentName] || componentsWithNestedContent[componentName]
      // prettier-ignore
      if (typeof component !== 'object' || component === null || !blokCouldRenderH1) return

      // Check for nested content in component(box, two-column-layout, ...)
      const nestedContent =
        !!componentsWithNestedContent[componentName] &&
        componentsWithNestedContent[componentName].filter(
          (contentField) => component[contentField].length > 0
        )
      if (nestedContent.length) {
        // If nested content, recursively call this function on content array
        nestedContent.forEach((field) => {
          if (component && component[field].length)
            parseContentForH1(component[field])
        })
      }

      if (checkH1Priority(component)) h1Prime = component
    })
  }

  parseContentForH1(initialArray)
  if (h1Prime) h1Prime.shouldRenderH1 = true
  return initialArray
}
